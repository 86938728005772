@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
breadcrumb
------------------------------------------------------------*/
.c-breadcrumb {
  // .c-breadcrumb__inner .
  &__inner {
    // display: flex;
    // flex-wrap: wrap;
    display: inline-block;

    @include r.sp {
    }
  }

  &__item {
    // display: flex;
    display: inline;

    @include r.sp {
    }

    &:not(:last-child) {
      // .c-breadcrumb__item:not(:last-child):after .
      &:after {
        margin-right: 0;
        margin-left: 4px;
        border-width: 5px 0 5px 8px;
        border-style: solid;
        border-color: transparent;
        border-left-color: #000;
        content: '';
        width: 0;
        height: 0;
        pointer-events: none;
        display: inline-block;
        position: relative;
        top: 1px;

        @include r.sp {
          margin: 4px 4px 0 6px;
          border-width: 4px 0 4px 7px;
        }
      }
    }

    &:nth-last-child(2):not(:first-child) {
      &:after {
        margin-left: 1px;
      }
    }
  }

  a,
  span {
    font-size: 1.2rem;
    line-height: 1.7;

    @include r.sp {
      font-size: 1rem;
    }
  }

  span {
    @include r.sp {
      letter-spacing: 0;
    }
  }

  a {
    letter-spacing: 1px;
    border-bottom: 1px solid v.$color17;

    @include r.hover {
      opacity: 1;
      border-color: transparent;
    }
  }

  > .l-container2 {
    @include r.calc(767, padding-left, 74, 767);
    @include r.calc(767, padding-right, 74, 767);
  }
}
