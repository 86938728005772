@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
list
------------------------------------------------------------*/
.c-list1 {
  padding-left: 48px;
  border-left: 3px solid v.$color11;
  position: relative;

  @include r.sp {
    padding-left: 26px;
    border-left-width: 1px;
  }

  // .c-list1:after .
  &:after {
    content: '';
    width: 3px;
    height: 34px;
    top: 0;
    left: -3px;
    background-color: v.$color4;
    position: absolute;

    @include r.sp {
      height: 17px;
      left: -1px;
      width: 1px;
    }
  }

  &__item {
    padding-bottom: 21px;
    border-bottom: 1px dotted #ccc;

    @include r.sp {
      padding-bottom: 8px;
    }

    // .c-list1__item:not(:last-child) .
    &:not(:last-child) {
      margin-bottom: 22px;

      @include r.sp {
        margin-bottom: 8px;
      }
    }
  }

  // .c-list1__link .
  &__link {
    @include r.pc {
      display: flex;
      width: max-content;
      max-width: 100%;
    }

    @include r.sp {
      width: 100%;
      display: block;
    }
  }

  // .c-list1__date .
  &__date {
    letter-spacing: -0.2px;
    font-size: 1.6rem;
    line-height: 1;
    color: v.$color4;
    width: 88px;

    @include r.sp {
      margin-bottom: 4px;
      display: block;
      font-size: 1.2rem;
    }
  }

  // .c-list1__title .
  &__title {
    width: 100%;
    font-size: 1.4rem;
    color: #333;
    line-height: 1;

    @include r.pc {
      max-width: calc(100% - 88px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include r.sp {
      font-size: 1.2rem;
      line-height: 1.5;
    }
  }
}

/*
------------------------------------------------------------*/
.c-list2 {
  @include r.pc {
    margin: 0 -18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include r.sp {
    padding: 0 30px;
  }

  @include r.max-w(374px) {
    padding: 0 10px;
  }

  // .c-list2__item .
  &__item {
    width: 33.3333%;

    @include r.pc {
      margin-bottom: 77px;
      padding: 0 18px;
      height: 100%;
    }

    @include r.pad {
      width: 50%;
    }

    @include r.sp {
      width: 100%;
    }

    &:not(:last-child) {
      @include r.sp {
        margin-bottom: 51px;
      }
    }
  }

  // .c-list2__img .
  &__img {
    margin: 22px 0 34px;
    text-align: center;

    @include r.pc {
      padding-left: 7px;
    }

    @include r.sp {
      margin: 21px 0 14px;
      padding-right: 8px;
    }

    img {
      width: auto;
      display: inline-block;
      max-width: 99px;

      @include r.sp {
        max-width: 75px;
        width: 100%;
      }
    }
  }

  // .c-list2__title .
  &__title {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 500;
    color: v.$color11;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.5px;

    @include r.pc {
      min-height: 72px;
    }

    @include r.sp {
      letter-spacing: 1.1px;
      font-size: 2.25rem;
    }
  }

  // .c-list2__num .
  &__num {
    margin-bottom: -60px;
    top: -31px;
    left: -1px;
    @include r.josefin;
    font-size: 6rem;
    color: v.$color4;
    position: relative;
    z-index: 2;
    width: max-content;
    line-height: 1;
    min-width: 70px;

    @include r.sp {
      top: -28px;
      left: 10px;
      margin-bottom: -46px;
      min-width: 45px;
      font-size: 4.5rem;
    }

    // .c-list2__num__text .
    &__text {
      line-height: 1;
      z-index: 1;
      position: relative;
    }

    // .c-list2__num:after .
    &:after {
      content: '';
      z-index: 0;
      width: 100%;
      height: 51%;
      top: 0;
      right: 0;
      background-color: v.$color3;
      position: absolute;

      @include r.sp {
        height: 58%;
      }
    }
  }

  // .c-list2__box .
  &__box {
    padding: 18px 20px 40px;
    background-color: #fff;
    border-radius: 4px;
    height: 100%;
    position: relative;
    z-index: 1;

    @include r.sp {
      padding: 23px 15px 30px;
    }
  }

  &__inner {
    height: 100%;
    position: relative;

    @include r.pc {
      padding-right: 10px;
    }

    // .c-list2__inner:after .
    &:after {
      right: 0;
      top: -10px;
      border-radius: 4px;
      border: 1px solid v.$color8;
      z-index: 0;
      content: '';
      position: absolute;
      width: calc(100% - 20px);
      height: 100%;

      @include r.sp {
        right: -10px;
      }
    }
  }

  // .c-list2__text .
  &__text {
    letter-spacing: 0.9px;
    font-size: 1.4rem;
    line-height: 1.7;

    @include r.sp {
      padding-left: 10px;
      padding-right: 5px;
      font-size: 1.2rem;
    }
  }

  // .c-list2 .c-btn1 .
  .c-btn1 {
    margin-top: 37px;
    width: 100%;
    text-align: center;

    @include r.pc {
      padding-left: 7px;
    }

    @include r.sp {
      padding-right: 3px;
      margin-top: 15px;
    }

    // .c-list2 .c-btn1__txt .
    &__txt {
      padding: 13px 10px;
      font-weight: 500;
      font-size: 1.4rem;
      color: #333;
      max-width: 200px;
      display: inline-block;
      line-height: 1;
      border-radius: 4px;
      background: linear-gradient(to bottom, #52f7e4 50%, #4eead8 50%);

      @include r.sp {
        border-radius: 2px;
        padding: 10px 5px;
        max-width: 150px;
        font-size: 1.05rem;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-list3 {
  margin: 0 -25px;
  $root: &;
  @include r.sp {
    margin: 0;
  }

  // .c-list3__link .
  &__link {
    display: block;
    height: 100%;
    padding: 52px 19px 42px;
    border: 4px solid v.$color10;
    position: relative;

    @include r.sp {
      padding: 40px 15px 31px;
      border-width: 2px;
      border-radius: 2px;
    }
  }

  //-----------------------------------------------------
  &__wrap {
    flex-wrap: wrap;
  }

  //-----------------------------------------------------
  &__item {
    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    width: calc((100% / 3) - 50px);
    height: auto;
    margin: 0 25px 50px;
    @include r.max-w(768px) {
      width: calc((100% / 3) - 30px);
      margin: 0 15px 50px;
    }
    @include r.sp {
      width: 100%;
      margin: 0;
    }

    &:not(:last-child) {
      @include r.sp {
        margin: 0 0 30px;
      }
    }

    &::before {
      content: '';
      width: 37px;
      height: 40px;
      background: url(p.$path + '/images/common/icon/icon-play.svg') no-repeat
          center,
        v.$color10;
      background-size: 17px 17px;
      border-radius: 0 4px 4px 0;
      position: absolute;
      top: 48px;
      left: -1px;
      @include r.sp {
        width: 30px;
        height: 30px;
        background-size: 13px 13px;
        border-radius: 0 2px 2px 0;
        top: 37px;
      }
    }
  }

  //-----------------------------------------------------
  &__tag {
    width: auto;
    text-align: center;
    background: v.$color3;
    border-radius: 0 0 4px 0;
    font-size: 1.4rem;
    color: v.$color16;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding: 3px 11px 6px 11px;
    position: absolute;
    top: 0;
    left: 0;
    @include r.sp {
      font-size: 1.05rem;
      letter-spacing: 1px;
      top: 1px;
      left: 1px;
      padding: 2px 10px 4px 10px;
    }
  }

  //-----------------------------------------------------
  &__ttl {
    max-width: calc(100% - 50px);
    margin: 0 auto;
    text-align: center;
    font-size: 2rem;
    line-height: 1.5;
    color: v.$color11;
    font-weight: 500;
    letter-spacing: 1.6px;
    display: flex; flex-wrap: wrap; align-items: center;justify-content: center;
    @include r.sp {
      font-size: 1.5rem;
    }
  }

  //-----------------------------------------------------
  // .c-list3__img .
  &__img {
    width: 170px;
    height: 170px;
    margin: 24px auto 0;
    max-width: 100%;
    border-radius: 50%;
    overflow: hidden;
    @include r.sp {
      width: 135px;
      height: 135px;
      margin: 10px auto 0;
    }

    // .c-list3__img img .
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  //-----------------------------------------------------
  &__info {
    margin: 24px 0 0;
    @include r.sp {
      margin: 18px 0 0;
    }
  }

  //-----------------------------------------------------
  &__txt {
    font-size: 1.6rem;
    line-height: 1.65;
    color: v.$text-color;
    letter-spacing: 1.5px;
    @include r.sp {
      font-size: 1.2rem;
      letter-spacing: 1px;
    }
  }

  //-----------------------------------------------------
  &__listtag {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0;
    @include r.sp {
      margin: 14px 0 0;
    }

    .c-tag1 {
      min-width: 100px;
      margin-bottom: 5px;
      @include r.sp {
        min-width: 75px;
      }

      &:not(:last-child) {
        margin-right: 10px;
        @include r.sp {
          margin-right: 7px;
        }
      }
    }
  }

  //-----------------------------------------------------
  &.js-list3-slider {
    @include r.sp {
      margin: 0 -15px;
    }

    #{$root}__wrap {
      @include r.sp {
        flex-wrap: nowrap;
      }
    }

    #{$root}__item {
      @include r.sp {
        margin: 0;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-list4 {
  margin: 0 -25px;
  overflow: inherit;
  $root: &;
  @include r.sp {
    margin: 0;
  }

  .c-tag2 {
    @include r.max-w(768px) {
      padding: 6px 3px 6px 7px;
      font-size: 1.4rem;
      width: 120px;
    }

    @include r.sp {
      padding: 5px 5px 5px 7px;
      width: 98px;
      font-size: 1.2rem;
    }
  }

  &__inner {
    display: block;
    height: 100%;
  }
  //-----------------------------------------------------
  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  //-----------------------------------------------------
  &__item {
    width: calc((100% / 3) - 50px);
    margin: 0 25px 55px;
    height: auto;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba($color: #000, $alpha: 0.08);
    position: relative;
    @include r.max-w(768px) {
      width: calc((100% / 3) - 30px);
      margin: 0 15px 55px;
    }
    @include r.sp {
      width: 100%;
      margin: 0;
      border-radius: 2px;
    }

    &:not(:last-child) {
      @include r.sp {
        margin: 0 0 30px;
      }
    }
    & > a {
      display: block;
    }
  }

  //-----------------------------------------------------
  &__img {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    padding-top: 70.588%;
    position: relative;
    overflow: hidden;
    @include r.sp {
      border-radius: 2px 2px 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      object-fit: cover;
    }
  }

  //-----------------------------------------------------
  &__info {
    padding: 30px 21px 47px;
    @include r.sp {
      padding: 20px 16px 15px;
    }
  }

  //-----------------------------------------------------
  &__taggroup {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    position: relative;
    margin: -17px 0 -12px;
    @include r.sp {
      margin: -19px 0 -11px;
    }

    .c-tag3 {
      margin: 0 0 0 10px;
    }
  }

  //-----------------------------------------------------
  &__txt {
    font-size: 1.4rem;
    line-height: 1.55;
    color: v.$text-color;
    letter-spacing: 1.4px;
    @include r.sp {
      font-size: 1.2rem;
      line-height: 1.66;
      letter-spacing: 1.1px;
    }
  }

  //-----------------------------------------------------
  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    text-align: center;
    background: v.$color12;
    border-radius: 50%;
    font-size: 1.8rem;
    line-height: 1;
    color: v.$color11;
    @include r.josefin;
    position: absolute;
    top: -9px;
    left: -2px;
    padding: 4px 0 0;
    z-index: 1;
    @include r.sp {
      width: 32px;
      height: 32px;
      font-size: 1.95rem;
      top: -10px;
    }
  }

  //-----------------------------------------------------
  &.js-list4-slider {
    @include r.sp {
      margin: 0 -15px;
      overflow: hidden;
    }

    #{$root}__wrap {
      @include r.sp {
        flex-wrap: nowrap;
      }
    }

    #{$root}__item {
      @include r.sp {
        margin: 10px 0;
      }
    }
  }

  //-----------------------------------------------------
  &--col1 {
    margin: 0;
    padding: 0 2px;
    overflow: inherit;
    @include r.sp {
      padding: 0 4px;
    }

    #{$root}__item {
      width: 100%;
      margin: 0;

      &:nth-child(2) {
        #{$root}__number {
          background: v.$color18;
        }
      }

      &:nth-child(3) {
        #{$root}__number {
          background: v.$color19;
        }
      }

      &:nth-child(4) {
        #{$root}__number {
          background: v.$color20;
        }
      }

      &:nth-child(5) {
        #{$root}__number {
          background: v.$color20;
        }
      }

      &:not(:last-child) {
        margin: 0 0 37.5px;
        @include r.sp {
          margin: 0 0 40px;
        }
      }
    }

    // .c-list4--col1 .c-list4__taggroup .
    #{$root}__taggroup {
      margin: -12px 0 -8px;
      @include r.sp {
        margin: -13px 0 -8px;
      }

      .c-tag3 {
        margin: 0 0 0 5px;
      }
    }

    #{$root}__info {
      padding: 18px 15px 10px;
      @include r.sp {
        padding: 19px 15px 11px;
      }
    }

    #{$root}__txt {
      font-size: 1rem;
      line-height: 1.65;
      letter-spacing: 1px;
      @include r.sp {
        font-size: 1.1rem;
      }
    }

    .c-tag2 {
      width: 78px;
      font-size: 1rem;
      letter-spacing: 1px;
      padding: 3px 2px 4px 8px;
      @include r.sp {
        width: 84px;
        font-size: 1.1rem;
        padding: 3px 2px 4px 8px;
      }
    }

    .c-tag3 {
      font-size: 1rem;
      padding: 4px 7px;
      @include r.sp {
        font-size: 1.1rem;
        padding: 3px 7px;
      }
    }
  }

  //-----------------------------------------------------
  &--col2 {
    margin: 0 -35px;
    @include r.max-w(768px) {
      margin: 0 -20px;
    }
    @include r.sp {
      margin: 0;
    }

    // .c-list4--col2 .c-list4__item .
    #{$root}__item {
      width: calc((100% / 2) - 70px);
      margin: 0 35px 55px;

      @include r.max-w(768px) {
        width: calc((100% / 2) - 40px);
        margin: 0 20px 55px;
      }
      @include r.sp {
        width: 100%;
        margin: 0;
      }

      &:not(:last-child) {
        @include r.sp {
          margin: 0 0 30px;
        }
      }
    }

    #{$root}__taggroup {
      @include r.sp {
        margin: -14px 0 -8px;
      }

      // .c-list4--col2 .c-list4__taggroup .c-tag3 .
      .c-tag3 {
        @include r.sp {
          margin: 0 0 0 7px;
        }
      }
    }

    #{$root}__info {
      @include r.sp {
        padding: 21px 12px 35px;
      }
    }

    // .c-list4--col2 .c-tag2 .
    .c-tag2 {
      @include r.sp {
        width: 98px;
        padding: 4px 3px 5px 3px;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-list5 {
  $root: &;
  @include r.sp {
    padding: 0 10px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;

    @include r.max-w(768px) {
      margin: 0 -10px;
    }

    @include r.sp {
      margin: 0;
      justify-content: space-between;
    }
  }

  // .c-list5__item .
  &__item {
    margin-bottom: 31px;
    width: 210px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    position: relative;

    @include r.max-w(768px) {
      padding: 0 10px;
      width: 33.3333%;
      margin: 0 0 20px;
    }

    @include r.sp {
      width: 46.9%;
      margin-bottom: 16px;
      min-height: 43px;
      padding: 0;
    }
    &::before,
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 5px;
      height: 1px;
      background-color: v.$color10;
      right: 10px;
      @include r.max-w(768px) {
        right: 20px;
      }
      @include r.sp {
        right: 6px;
      }
    }

    // .c-list5__item::before .
    &::before {
      top: calc(50% - 2px);
      transform: rotate(40deg);
    }
    &:after {
      bottom: calc(50% - 2px);
      transform: rotate(-40deg);
    }
    @include r.hover {
      &::before,
      &:after {
        background-color: #ffffff;
        opacity: 1;
      }
      #{$root}__link {
        background-color: v.$color10;
        color: #fff;
        opacity: 1;
      }
    }
    &.is-active {
      &::before,
      &:after {
        background-color: #ffffff;
        opacity: 1;
      }
      #{$root}__link {
        background-color: v.$color10;
        color: #fff;
        opacity: 1;
      }
    }
  }
  &__link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid v.$color10;
    color: v.$color10;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 1.2px;
    @include r.sp {
      font-size: 1.2rem;
      letter-spacing: 1px;
      padding: 5px 13px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-list6 {
  $root: &;

  //-----------------------------------------------------
  &__item {
    background: #ffffff;
    padding: 14px 37px 17px;
    display: flex;
    position: relative;
    @include r.sp {
      display: block;
      line-height: 1.2;
      padding: 3px 10px 7px 14px;
    }

    &:not(:last-child) {
      margin: 0 0 20px;
      @include r.sp {
        margin: 0 0 15px;
      }
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  &__txt {
    display: block;
    width: calc(100% - 137px);
    font-size: 1.6rem;
    line-height: 1.5;
    color: v.$text-color;
    @include r.sp {
      width: auto;
      display: inline;
      font-size: 1.2rem;
      letter-spacing: 1.1px;
    }
  }

  //-----------------------------------------------------
  &__date {
    width: 110px;
    font-size: 1.6rem;
    line-height: 1.5;
    color: v.$color8;
    letter-spacing: 1.7px;
    @include r.sp {
      width: auto;
      display: inline;
      font-size: 1.2rem;
      letter-spacing: 1.1px;
    }
  }

  //-----------------------------------------------------
  // c-list6__txt .
  &__txt {
  }
}

/*
------------------------------------------------------------*/
.c-list7 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  $root: &;
  @include r.max-w(768px) {
    margin: 0 -15px;
  }
  @include r.sp {
    margin: 0;
    padding: 0 10px;
  }

  //-----------------------------------------------------
  // .c-list7__item .
  &__item {
    width: calc((100% / 2) - 60px);
    margin: 0 30px;

    @include r.max-w(768px) {
      width: calc((100% / 2) - 30px);
      margin: 0 15px;
    }
    @include r.sp {
      width: 100%;
      margin: 0;
    }

    &:not(:last-child) {
      @include r.sp {
        margin: 0 0 41px;
      }
    }
  }

  //-----------------------------------------------------
  &__box {
    background: v.$color3;
    padding: 11px 20px 20px;
    position: relative;
    @include r.sp {
      padding: 5px 10px 25px;
    }

    &::before {
      content: '';
      width: 80px;
      height: 80px;
      background: v.$color4;
      position: absolute;
      top: -19px;
      left: 0;
      @include r.sp {
        width: 60px;
        height: 60px;
        top: -11px;
      }
    }

    &::after {
      content: '';
      width: 40px;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 0;
      left: 40px;
      @include r.sp {
        width: 20px;
        height: 1px;
      }
    }

    &:not(:last-child) {
      margin: 0 0 61px;
      @include r.sp {
        margin: 0 0 41px;
      }
    }
  }

  //-----------------------------------------------------
  &__ttl {
    font-size: 2.4rem;
    line-height: 1.5;
    color: v.$color11;
    font-weight: 500;
    letter-spacing: 1.8px;
    margin: 0 0 0 16px;
    position: relative;
    z-index: 2;
    @include r.sp {
      letter-spacing: 0.7px;
      margin: 0 0 0 22px;
    }
  }

  //-----------------------------------------------------
  &__info {
    margin: 34px 0 0;
    @include r.sp {
      margin: 28px 0 0;
    }
  }

  //-----------------------------------------------------
  &--color2 {
    #{$root}__box {
      background: #fff;

      &::before {
        background: v.$color12;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-list8 {
  @include r.sp {
    padding: 0 50px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-top: 53px;
    @include r.sp {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  &__item {
    padding-left: 20px;
    width: 33.33%;
    @include r.sp {
      padding-left: 0;
      width: 100%;
    }
    &:nth-child(n + 4) {
      margin-top: 50px;
    }
    &:nth-child(n + 2) {
      @include r.sp {
        margin-top: 17px;
      }
    }
  }
  // .c-list8__img .
  &__img {
    width: 100%;
    padding-top: 66.6667%;
    position: relative;
    overflow: hidden;
    figure {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__txt {
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include r.sp {
      font-size: 0.9rem;
    }
  }
  &__txt1 {
    font-size: 1.6rem;
    font-weight: 400;
    color: #333333;
    line-height: 1.7;
    @include r.sp {
      font-size: 1.2rem;
    }
  }
  &__link {
    display: block;
  }
  &__tlt {
    font-size: 2rem;
    font-weight: bold;
    color: #15766b;
    margin-bottom: 31px;
    margin-top: 12px;
    line-height: 1.6;
    @include r.sp {
      margin-bottom: 6px;
      font-size: 1.4rem;
      line-height: 1.7;
      margin-top: 7px;
      letter-spacing: -0.5px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-list9 {
  max-width: 1020px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  $root: &;
  @include r.sp {
    padding: 0 17px;
  }

  &__item {
    background-color: #ffffff;
    width: calc((100% / 3) - 40px);
    margin: 0 20px 60px;
    position: relative;
    @include r.sp {
      width: 100%;
      margin: 0;
    }

    &:not(:last-child) {
      @include r.sp {
        margin: 0 0 48.5px;
      }
    }
  }

  // .c-list9__ttl .
  &__ttl {
    padding: 6px 15px;
    min-height: 55px;
    background-color: v.$color10;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.2;
    color: #ffffff;
    font-weight: bold;
    letter-spacing: 1px;
    @include r.sp {
      min-height: 53px;
      letter-spacing: 1.2px;
      padding: 5px 10px;
    }
  }

  &__info {
    padding: 20px 20px 53px;
    @include r.sp {
      padding: 15px 20px 35px;
    }
  }

  &__img {
    width: 105px;
    height: 105px;
    margin: auto;
    background: v.$color26;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @include r.sp {
      width: 102px;
      height: 102px;
    }
  }

  &__txt {
    font-size: 1.4rem;
    color: v.$text-color;
    font-weight: 400;
    margin: 14px 0 0;
    @include r.sp {
      margin: 10px 0 0;
    }
  }

  // .c-list9__btn .
  &__btn {
    background: linear-gradient(to bottom, #fdcd06 50%, #fdc105 50%);
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 28%);
    border-radius: 10px;
    width: 197px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.6rem;
    text-shadow: 0px 1px 2px rgb(0 0 0 / 28%);
    color: #ffffff;
    margin: 0 auto;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    @include r.max-w(768px) {
      width: 84%;
    }
    @include r.sp {
      width: 194px;
      height: 38px;
      border-radius: 5px;
      bottom: -19px;
    }
  }

  &--color2 {
    #{$root}__item {
      background: v.$color26;
    }

    #{$root}__img {
      background: #fff;
    }
  }
}

/*
------------------------------------------------------------*/
.c-list10 {
  $root: &;

  //-----------------------------------------------------
  &__item {
    background: v.$color13;
    border-left: 3px solid v.$color12;
    padding: 6px 12px 7px 7px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include r.sp {
      padding: 10px 12px 10px 9px;
    }

    &:not(:last-child) {
      margin: 0 0 5px;
      @include r.sp {
        margin: 0 0 11px;
      }
    }
  }

  //-----------------------------------------------------
  // .c-list10__txt .
  &__txt {
    max-width: calc(100% - 130px);
    font-size: 1.4rem;
    line-height: 1.5;
    color: v.$color11;
    letter-spacing: 1.3px;
    word-break: break-word;
    @include r.max-w(768px) {
      width: 100%;
      max-width: 100%;
    }
    @include r.sp {
      max-width: 100%;
      font-size: 1.2rem;
      letter-spacing: 1.2px;
    }
  }

  //-----------------------------------------------------
  &__date {
    width: 125px;
    font-size: 1.1rem;
    line-height: 1.5;
    color: v.$color11;
    letter-spacing: 0.6px;
    margin: 3px 0 0;
    @include r.max-w(768px) {
      width: 100%;
      text-align: right;
    }
    @include r.sp {
      margin: 2px 0 0;
      text-align: left;
    }
  }

  //-----------------------------------------------------
  &--color2 {
    #{$root}__item {
      background: v.$color26;
    }
  }
}

/*
------------------------------------------------------------*/
.c-list11 {
  position: relative;
  @include r.sp {
    padding: 0 25px;
  }
  .swiper-button-next {
    right: 2px;
    background: url(p.$path + '/images/common/icon-next.jpg') no-repeat center /
      contain;
    @include r.sp {
      top: 25%;
      right: 10px;
    }
  }
  .swiper-button-prev {
    left: 1px;
    background: url(p.$path + '/images/common/icon-prev.jpg') no-repeat center /
      contain;
    @include r.sp {
      top: 25%;
      left: 10px;
    }
  }
  // .c-list11 .swiper-button-next, .c-list11 .swiper-button-prev .
  .swiper-button-next,
  .swiper-button-prev {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #ffffff;
    top: 51%;
    @include r.sp {
      width: 25px;
      height: 25px;
      top: 27.3vw;
    }
    &:after {
      font-size: 0.8rem;
      color: transparent;
      font-weight: bold;
    }
    &:focus-visible,
    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;

      &:after,
      &:before {
        display: none;
      }
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    @include r.sp {
      flex-wrap: wrap;
    }
  }
  // .c-list11__img .
  &__img {
    display: inline-block;
    width: 52.4%;
    padding-left: 10.1%;
    @include r.pc {
      padding-bottom: 10px;
    }
    @include r.max-w(880px) {
      width: 50.7%;
      padding-left: 6.6%;
    }
    @include r.max-w(768px) {
      width: 48%;
      padding-left: 6%;
    }
    @include r.sp {
      width: 100%;
      padding-left: 6px;
    }

    figure {
      display: inline-block;
      padding-top: 66.596%;
      position: relative;
      width: 100%;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        @include r.sp {
          // top: -5px;
          // right: -5px;
        }
      }

      // .c-list11__img figure:after .
      &:after {
        width: 100%;
        height: 100%;
        top: 10px;
        left: -10px;
        border: 1px solid v.$color10;
        content: '';
        position: absolute;
        z-index: 0;

        @include r.sp {
          top: 6px;
          left: -6px;
        }
      }
    }
  }
  // .c-list11__content .
  &__content {
    width: 42.25%;
    padding-right: 10%;
    padding-top: 10px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    @include r.max-w(880px) {
      padding-right: 7%;
      width: 45%;
    }
    @include r.max-w(768px) {
      padding-right: 7.5%;
      width: 49%;
    }
    @include r.sp {
      margin-top: 11px;
      padding: 0 15px 0 30px;
      width: 100%;
    }
  }
  &__txt {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.6;
    @include r.sp {
      font-size: 1.4rem;
      letter-spacing: -0.3px;
    }
  }
  &__txt1 {
    font-size: 1.4rem;
    color: v.$color11;
    font-weight: 500;
    // margin-right: -23%;
    letter-spacing: 1.1px;
    position: relative;
    display: inline-block;
		padding-right: 40px;

    @include r.sp {
      margin-right: 0;
      font-size: 1.1rem;
      letter-spacing: 0;
			padding-right: 14px;
    }
    &:before,
    &:after {
      content: '';
      display: block;
    }
    // .c-list11__txt1:before .
    &:before {
      position: absolute;
      right: 0;
      bottom: 9px;
      width: 10px;
      height: 1px;
      background-color: #333333;
      transform: rotate(66deg);
      transform: rotate(49deg);
      @include r.sp {
        // right: -16px;
        bottom: 7px;
        width: 7px;
      }
    }
    // .c-list11__txt1:after .
    &:after {
      right: 0;
      bottom: 5px;
      width: 23px;
      height: 1px;
      background-color: #333333;
      position: absolute;
      @include r.sp {
        // right: -15px;
        bottom: 4px;
        width: 11px;
      }
    }
  }
  // .c-list11__tlt .
  &__tlt {
    color: v.$color11;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 52px;
    position: relative;
    letter-spacing: 2px;
    @include r.sp {
      font-size: 1.4rem;
      margin-bottom: 24px;
      letter-spacing: 1px;
    }
    &::before {
      content: '';
      display: inline-block;
      height: 3px;
      width: 20px;
      background-color: v.$color10;
      position: absolute;
      left: 0;
      bottom: -27px;
      @include r.sp {
        height: 2px;
        bottom: -15px;
        width: 15px;
      }
    }
  }
  .c-text1 {
    letter-spacing: -0.3px;
    @include r.sp {
      font-size: 1.2rem;
      margin-top: 5px;
    }
  }
  // .c-list11__btn .
  &__btn {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include r.sp {
      margin: 22px 2px 0 -29px;
    }
    // .c-list11__btn .c-btn1 .
    .c-btn1 {
      width: 200px;
      @include r.max-w(1110px) {
        max-width: 150px;
        width: 100%;
      }
      @include r.max-w(880px) {
        max-width: 130px;
      }
      @include r.sp {
        width: 148px;
        max-width: 100%;
      }
      @include r.max-w(360px) {
        width: 45%;
      }
      // .c-list11__btn .c-btn1__txt .
      &__txt {
        padding: 11.5px 10px;
        font-size: 1.4rem;
        @include r.sp {
          font-size: 1.2rem;
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-list12 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1080px;
  margin: 0 auto;
  @include r.sp {
    flex-direction: column;
    align-items: center;
  }

  //-----------------------------------------------------
  &__item {
    width: calc((100% / 3) - 60px);
    margin: 0 30px;
    background: v.$color10;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    @include r.max-w(1120px) {
      width: calc((100% / 3) - 30px);
      margin: 0 15px;
    }
    @include r.sp {
      width: 100%;
      margin: 0;
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;
    }

    &:not(:last-child) {
      @include r.sp {
        margin: 0 0 27px;
      }
    }
  }

  //-----------------------------------------------------
  // .c-list12__link .
  &__link {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px 10px 32px;
    @include r.sp {
      padding: 10px 10px 24px;
    }

    &::after {
      content: '';
      width: 27px;
      height: 27px;
      background: url(p.$path + '/images/common/icon/icon-arrow-circle.png')
        no-repeat center / 27px 27px;
      position: absolute;
      left: 50%;
      bottom: 17px;
      transform: translate(-50%, 0);
      @include r.sp {
        width: 20px;
        height: 20px;
        background-size: 20px;
        bottom: 11px;
      }
    }
  }

  //-----------------------------------------------------
  &__ttl {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    line-height: 1.7;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px;
    @include r.max-w(1120px) {
      font-size: 1.4vw;
    }
    @include r.sp {
      font-size: 1.4rem;
      letter-spacing: 0.8px;
    }

    &.is-style1 {
      font-size: 3rem;
      @include r.max-w(1120px) {
        font-size: 2.4vw;
      }
      @include r.sp {
        font-size: 2rem;
      }
    }
  }

  //-----------------------------------------------------
  &__img {
    text-align: center;
    height: 90px;
    width: 90px;
    margin: 17px 0 0;
    border-radius: 50%;
    overflow: hidden;
    @include r.max-w(1120px) {
      height: 8vw;
      width: 8vw;
    }
    @include r.sp {
      height: 65px;
      width: 65px;
      margin: 11px 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/*
------------------------------------------------------------*/
.c-list13 {
  @include r.sp {
    padding: 0 10px;
  }
  &__inner {
    background-color: #34d9c6;
  }
  .c-title5 {
    padding: 0;
    position: relative;
    top: 80px;
    @include r.sp {
      position: static;
      padding-top: 26px;
      padding-bottom: 24px;
    }
  }
  &__list {
    display: flex;
    margin-left: -20px;
    position: relative;
    top: 150px;
    @include r.max-w(768px) {
      padding: 0 10px;
      flex-wrap: wrap;
      margin-left: 0;
      justify-content: center;
    }
    @include r.sp {
      padding: 0 43px;
      position: static;
      margin-left: 0;
    }
  }
  &__item {
    padding-left: 20px;
    width: 33.33%;
    @include r.max-w(768px) {
      width: calc(50% - 20px);
      padding-left: 0;
      margin: 0 10px 75px;
    }
    @include r.sp {
      width: 100%;
      margin: 0 0 35px;
    }
    &:last-child {
      @include r.sp {
        margin-bottom: 28px;
      }
    }
  }
  &__tag {
    padding: 4px 25px;
    line-height: 1.5;
    background-color: #ffbf00;
    color: #ffffff;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 30px;
    position: relative;
    display: inline-flex;
    align-items: center;
    position: relative;
    top: -22px;
    @include r.sp {
      font-size: 1.4rem;
      padding: 3px 17px;
      top: -14px;
      left: -1px;
    }

    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 10px solid #ffbf00;
      bottom: -7px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    span {
      font-size: 3.6rem;
      margin-left: 3px;
      position: relative;
      top: -5px;
      @include r.sp {
        font-size: 2.4rem;
      }
    }
  }
  &__content {
    padding: 0 24px;
    @include r.sp {
      padding: 0 10px;
    }
  }
  figure {
    display: block;
    margin: 32px auto;
    width: 200px;
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @include r.sp {
      width: 135px;
      margin: 20px auto;
    }
    img {
      max-width: 100%;
      display: inline-block;
      margin: auto;
    }
  }
  .c-text1 {
    letter-spacing: 1.3px;
    @include r.sp {
      font-size: 1.2rem;
      letter-spacing: 0.9px;
    }
  }
  &__wrap {
    background-color: #ffffff;
    height: 100%;
    padding-bottom: 5px;
    position: relative;
    @include r.sp {
      padding-bottom: 20px;
    }
    &:after {
      content: '';
      display: inline-block;
      height: 40px;
      width: 100%;
      background-color: #ffffff;
      position: absolute;
      bottom: -37px;
      @include r.sp {
        content: normal;
      }
    }
  }
  &__tlt {
    font-size: 2.4rem;
    font-weight: bold;
    color: v.$color10;
    letter-spacing: 2px;
    text-align: center;
    @include r.sp {
      font-size: 1.6rem;
    }
  }
}
