@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
img
------------------------------------------------------------*/
.c-imgtext1 {
  display: flex;
  flex-wrap: wrap;
  $root: &;

  + #{$root} {
    margin: 80px 0 0;
  }

  //-----------------------------------------------------
  &__img {
    width: 50%;
    position: relative;
    @include r.sp {
      width: 100%;
    }

    // .c-imgtext1__img::after .
    &::after {
      content: '';
      width: 62px;
      height: 130px;
      top: -1px;
      background: #fff;
      position: absolute;
      right: 0;
      @include r.sp {
        width: 61px;
        height: 36px;
        top: auto;
        bottom: 0;
        right: auto;
        left: 0;
      }
    }

    &::before {
      content: '';
      width: 40px;
      height: 6px;
      background: v.$color4;
      position: absolute;
      top: 31px;
      right: -20px;
      z-index: 2;
      @include r.sp {
        width: 20px;
        height: 3px;
        top: auto;
        bottom: 0;
        right: auto;
        left: 12px;
      }
    }
  }

  //-----------------------------------------------------
  &__info {
    width: 34.5%;
    margin: 0 0 0 4%;
    padding: 7px 0 0 0;
    @include r.max-w(1120px) {
      width: 42%;
    }
    @include r.sp {
      width: 100%;
      margin: 23px 0 0;
      padding: 0 11px;
    }
  }

  //-----------------------------------------------------
  &__ttl {
    font-size: 3rem;
    line-height: 1.7;
    color: v.$color11;
    font-weight: bold;
    letter-spacing: 1.1px;
    @include r.max-w(1120px) {
      font-size: 2.5rem;
    }
    @include r.sp {
      font-size: 1.8rem;
      letter-spacing: 0.65px;
    }
  }

  //-----------------------------------------------------
  &__txt {
    font-size: 1.6rem;
    line-height: 1.68;
    color: v.$text-color;
    letter-spacing: 0.45px;
    margin: 44px 0 0 5px;
    @include r.sp {
      font-size: 1.2rem;
      letter-spacing: 0.4px;
      margin: 17px 0 0 0;
    }
  }

  .c-btn1 {
    margin: 55px 0 0 5px;
    @include r.sp {
      margin: 29px auto 0;
    }
  }

  //-----------------------------------------------------
  &--reverse {
    flex-direction: row-reverse;

    #{$root}__info {
      width: 35%;
      margin: 0 4% 0 0;
      padding: 22px 0 0;
      @include r.max-w(1120px) {
        width: 40%;
      }
      @include r.sp {
        width: 100%;
        margin: 24px 0 0;
        padding: 0 0 0 10px;
      }
    }

    #{$root}__img {
      &::before {
        top: 46px;
        left: -20px;
        @include r.sp {
          top: auto;
          bottom: 0;
          left: auto;
          right: 10px;
        }
      }

      // .c-imgtext1--reverse .c-imgtext1__img::after .
      &::after {
        left: -1px;
        right: auto;
        @include r.pc {
          width: 63px;
        }
        @include r.sp {
          left: auto;
          right: 0;
        }
      }
    }

    .c-btn1 {
      margin: 55px 0 0 auto;
      @include r.sp {
        margin: 26px auto 0;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-imgtext2 {
  // .c-imgtext2__img .
  &__img {
    margin-bottom: 10px;
    text-align: center;

    @include r.sp {
      margin-bottom: 20px;
    }

    img {
      display: inline-block;
    }
  }

  // .c-imgtext2__labels .
  &__labels {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-end;

    @include r.sp {
      margin-bottom: 10px;
    }
  }

  .c-tag2,
  .c-tag3 {
    margin-right: 10px;
  }
}

/*
------------------------------------------------------------*/
.c-imgtext3 {
  @include r.sp {
    padding-left: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  > .l-container2 {
    @include r.sp {
      padding: 0;
    }
  }

  // .c-imgtext3__logo .
  &__logo {
    width: 200px;
    margin-bottom: 10px;

    @include r.sp {
      width: 100%;
    }

    img {
      @include r.sp {
        max-width: 150px;
        width: 100%;
        display: inline-block;
      }
    }
  }

  // .c-imgtext3__inner .
  &__inner {
    padding-top: 80px;
    position: relative;

    @include r.calc(1180, padding-top, 80, 1180);

    @include r.sp {
      padding-top: 53px;
    }

    @include r.calc(374, padding-top, 50, 375);
  }

  // .c-imgtext3__box .
  &__box {
    padding: 0 55px 59px 61px;
    width: 57.1%;
    position: relative;
    z-index: 1;
    background-color: #fff;

    @include r.pc {
      min-height: 407px;
    }

    @include r.calc(1180, min-height, 407, 1180);
    @include r.calc(1180, padding-left, 60, 1180);
    @include r.calc(1180, padding-right, 55, 1180);

    @include r.calc(1024, padding-left, 40, 1024);
    @include r.calc(1024, padding-right, 40, 1024);

    @include r.pad {
      width: 65%;
    }

    @include r.sp {
      padding: 19px 10px 20px;
      width: 50%;
      min-height: unset;
    }

    @include r.max-w(600px) {
      width: calc(100% - 16px);
    }
  }

  // .c-imgtext3__btns .
  &__btns {
    margin-top: 40px;
    display: flex;

    @include r.sp {
      margin-top: 13px;
      justify-content: center;
    }
  }

  // .c-imgtext3__text .
  &__text {
    letter-spacing: 0.5px;
    font-size: 1.6rem;
    line-height: 1.7;
    padding-right: 55px;

    @include r.calc(1180, padding-right, 55, 1180);
    @include r.calc(1180, letter-spacing, 0.5, 1180);

    @include r.max-w(768px) {
      padding-right: 0;
    }

    @include r.sp {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }

  // .c-imgtext3__title .
  &__title {
    margin-bottom: 30px;
    font-weight: 700;
    line-height: 1;
    display: flex;
    flex-direction: column;

    @include r.pc {
      position: relative;
      top: -2px;
    }

    @include r.sp {
      margin-bottom: 9px;
    }

    // .c-imgtext3__title__top .
    &__top {
      color: v.$color11;
      font-size: 3.6rem;
      letter-spacing: 3.2px;

      @include r.calc(1180, font-size, 36, 1180);
      @include r.calc(1180, letter-spacing, 3, 1180);

      @include r.sp {
        letter-spacing: 1.6px;
        font-size: 1.8rem;
      }
    }

    // .c-imgtext3__title__bottom .
    &__bottom {
      letter-spacing: 3px;
      margin-top: 5px;
      color: v.$color10;
      font-size: 4.8rem;

      @include r.calc(1180, font-size, 48, 1180);
      @include r.calc(1180, letter-spacing, 3, 1180);

      @include r.sp {
        margin-top: 3px;
        letter-spacing: 1.5px;
        font-size: 2.4rem;
      }

      @include r.calc(374, font-size, 24, 375);
    }
  }

  // .c-imgtext3__img .
  &__img {
    width: 53.572%;
    padding-top: 35.715%;
    position: absolute;
    height: 0;
    z-index: 0;
    top: 0;
    right: 0;

    @include r.sp {
      width: 58%;
      padding-top: 32%;
    }

    @include r.max-w(414px) {
      width: 76%;
      padding-top: 55%;
    }

    // .c-imgtext3__img img .
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
    }

    &.is-style1 {
      background-color: #fff;

      img {
        object-fit: contain;
      }
    }
  }

  // .c-imgtext3 .c-btn1 .
  .c-btn1 {
    width: auto;
    min-width: 300px;

    @include r.max-w(768px) {
      min-width: 250px;
    }

    @include r.sp {
      width: calc(50% - 7.5px);
      min-width: auto;
    }

    // .c-imgtext3 .c-btn1__txt .
    &__txt {
      height: 100%;

      @include r.max-w(768px) {
        font-size: 1.6rem;
      }

      @include r.sp {
        padding: 7.7px 10px;
        font-size: 1.2rem;
        line-height: 1.51rem;
      }
    }

    // .c-imgtext3 .c-btn1 + .c-btn1 .
    & + .c-btn1 {
      margin-left: 30px;

      @include r.calc(1180, margin-left, 30, 1180);

      @include r.sp {
        margin-left: auto;
      }
    }
  }
}


.p-reception1 .c-imgtext3.onseparate {
  margin-bottom: -30px;
  @include r.sp {
    margin-bottom: -40px;
  }
}

.c-imgtext3_separate {
  margin: 0 auto 20px;
  padding: 0 30px;
  position: relative;
  z-index: 1;
  text-align: center;
  pointer-events: none;
  box-sizing: border-box;
  figure {
    display: block;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    text-align: center;
    img {
      display: block;
      margin: auto;
    }
  }
  @include r.sp {
    margin: 0 auto;
    padding: 0 16px;
    figure {
    }
  }
}

/*
------------------------------------------------------------*/
.c-img1 {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  @include r.sp {
    max-width: 100%;
  }

  img {
    display: inline;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
