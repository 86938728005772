@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
table
------------------------------------------------------------*/
.c-table1 {
  $root:&;
  @include r.sp {
    margin: 0 10px;
  }
  &__btn {
    padding: 4px 10px;
    border-radius: 5px;
    color: #fff;
    width: 100px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    @include r.sp {
      font-size: 1rem;
      padding: 5px;
      width: 57px;
      border-radius: 2px;
      letter-spacing: 0;
    }
    @include r.max-w(372px) {
      width: 47px;
    }
    &--green {
      background-color: #16c1ae;
    }
    &--yellow {
      background-color: #fdd835;
    }
    &--outline {
      border: 1px solid #16c1ae;
      color: #16c1ae;
      background-color: #fff;
    }
    & + #{$root}__btn {
      margin-top: 6px;
    }
  }
  &__btn1 {
    width: 80px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #cccccc;
    color: #333333;
    font-size: 1.4rem;
    @include r.sp {
      font-size: 1rem;
      border-radius: 2px;
      padding: 15px 10px;
      width: 40px;
      height: 60px;
      min-height: 60px;
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
    @include r.max-w(372px) {
      width: 35px;
      padding: 15px 5px;
    }
    &--blue {
      background-color: #00b7ee;
      color: #fff;
    }
  }
  span {
    font-size: 1.2rem;
    letter-spacing: -1px;
    position: relative;
    top: -4px;
    @include r.sp {
      font-size: 1rem;
      letter-spacing: 1px;
    }
  }
  tr {
    th {
      padding: 19.5px 10px;
      color: #fff;
      font-size: 1.4rem;
      font-weight: bold;
      background-color: #16c1ae;
      letter-spacing: 1px;
      @include r.pad {
        padding-left: 7px;
        padding-right: 7px;
      }
      @include r.sp {
        padding: 2.5px 5px;
      }
      &:first-child {
        width: calc((130px / 1120px) * 100%);
        @include r.sp {
          width: 16%;
        }
        @include r.max-w(372px) {
          width: 20%;
        }
      }
      &:nth-child(2) {
        width: calc((330px / 1120px) * 100%);
        @include r.sp {
          width: 33.1%;
        }
      }
      &:nth-child(3) {
        width: calc((200px / 1120px) * 100%);
      }
      &:nth-child(4) {
        width: calc((222px / 1120px) * 100%);
      }
      &:nth-child(5) {
        width: calc((130px / 1120px) * 100%);
        @include r.sp {
          width: 2.5%;
        }
      }
      &:nth-child(6) {
        width: calc((100px / 1120px) * 100%);
        @include r.sp {
          width: 9%;
          letter-spacing: 2px;
        }
      }
    }
    th,
    td {
      border: 2px solid #fff;
      @include r.sp {
        border: 1px solid #fff;
      }
    }
    td {
      background-color: #eff4fa;
      padding: 5px 10px;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0px;
      height: 77px;
      min-height: 77px;
      @include r.pad {
        padding-left: 7px;
        padding-right: 7px;
      }
      @include r.sp {
        padding: 5px 5px;
        font-size: 1.2rem;
        height: 123.5px;
        min-height: 123.5px;
      }
      &:first-child {
        padding: 5px 20px;
        @include r.pad {
          padding-left: 7px;
          padding-right: 7px;
        }
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        padding-left: 20px;
        padding-right: 10px;
        @include r.pad {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      &:nth-child(2) {
        @include r.pad {
          font-size: 1.4rem;
          letter-spacing: 0.7px;
          line-height: 17px;
        }
      }
      &:nth-child(5),
      &:last-child {
        text-align: center;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-table2 {
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  //-----------------------------------------------------
  th,
  td {
    font-size: 1.6rem;
    line-height: 1.5;
    color: v.$text-color;
    letter-spacing: 1.3px;
    padding: 17.5px 19px;
    @include r.sp {
      font-size: 1.4rem;
      padding: 4px 5px 2px;
      letter-spacing: 1.1px;
    }
  }

  //-----------------------------------------------------
  th {
    width: 280px;
    color: v.$color8;
    font-weight: 500;
    border-bottom: 1px solid v.$color8;
    @include r.sp {
      width: 92px;
      padding: 28.5px 5px 26.5px;
    }
  }

  //-----------------------------------------------------
  td {
    border-bottom: 1px solid v.$color21;
  }
}

/*
------------------------------------------------------------*/
.c-table3 {
  width: calc(100% + 8px);
  margin: 0 -4px;
  border-spacing: 4px;
  border-collapse: separate;
  box-sizing: border-box;
  @include r.sp {
    width: 100%;
    margin: 0;
    border-spacing: 2.5px;
    padding: 0 8px;
  }

  //-----------------------------------------------------
  th,
  td {
    background: #fff;
    font-size: 1.6rem;
    line-height: 1.64;
    color: v.$text-color;
    letter-spacing: 1.3px;
    padding: 13px 21px 15.8px;
    @include r.sp {
      display: block;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.45;
      padding: 8px 10px 0;
    }
  }

  //-----------------------------------------------------
  th {
    width: 175px;
    text-align: right;
    vertical-align: top;
    @include r.sp {
      width: 100%;
      text-align: center;
    }

    span {
      display: inline-block;
      width: 40px;
      margin: 0 0 0 16px;
      @include r.sp {
        width: auto;
        margin: 0 0 0 4px;
      }
    }
  }

  //-----------------------------------------------------
  td {
    padding: 13px 5px 15.8px 27px;
    @include r.sp {
      padding: 3px 10px 10px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-table4 {
  width: 100%;
  // .c-table4 .c-title3 .
  .c-title3 {
    @include r.sp {
      padding: 0 41px;
    }
  }
  // .c-table4__wrap .
  &__wrap {
    position: relative;
    margin-top: 53px;
    @include r.sp {
      overflow-y: scroll;
      margin-top: 23px;
    }
  }
  tr {
    // .c-table4 tr th .
    th {
      border: 2px solid #fff;
      background-color: #d8f0ed;
      padding: 14px 10px 11px;
      border-top: none;
      @include r.sp {
        padding: 6px 5px 5px;
      }
      &:first-child {
        border-left: none;
        width: 180px;
        @include r.sp {
          width: 115px;
        }
      }
      &:not(:first-child) {
        width: 233px;
        @include r.sp {
          width: 147px;
        }
      }
      &:last-child {
        border-right: none;
      }
    }
    td {
      border: 2px solid #fff;
      text-align: center;
      height: 70px;
      min-height: 70px;
      font-size: 1.6rem;
      font-weight: 500;
      @include r.sp {
        font-size: 1.2rem;
        height: 44px;
        min-height: 44px;
      }
      &:first-child {
        background-color: #d8f0ed;
        padding: 10px;
        border-left: none;
      }
      &:last-child {
        @include r.sp {
          border-right: 0;
        }
      }
      &:not(:first-child) {
        background-color: v.$color13;
      }
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  &__txt {
    font-size: 1.4rem;
    font-weight: 400;
    color: #333333;
    line-height: 1.5;
    @include r.sp {
      font-size: 1rem;
    }
    // .c-table4__txt--bold .
    &--bold {
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.1;
      letter-spacing: 2px;
      @include r.sp {
        font-size: 1.3rem;
        line-height: 1.3;
      }
    }
  }
  // .c-table4__content .
  &__content {
    width: 100%;
    margin-bottom: 24px;
    @include r.sp {
      margin-bottom: 11px;
      width: 100%;
      width: 700px;
    }
  }
  &__note {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: 1px;
    @include r.sp {
      font-size: 1rem;
      line-height: 1.7;
      padding-right: 40px;
    }
    @include r.max-w(370px) {
      padding-right: 0;
    }
  }
}

/*
------------------------------------------------------------*/
.c-table5 {
  font-size: 1.6rem;
  line-height: 1.7;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 1.6rem;
  line-height: 1.9;
  width: 100%;
  max-width: 1004px;
  margin-left: auto;
  margin-right: auto;

  @include r.pc {
    text-align: left;
  }

  @include r.sp {
    line-height: 1.7;
    padding: 0 23px;
  }

  @include r.max-w(374px) {
    padding: 0;
  }

  &__table {
    width: 100%;
  }

  // .c-table5__th, .c-table5__td .
  &__th,
  &__td {
    padding: 14.6px 19px;

    @include r.pc {
      border: 2px solid v.$color13;
    }

    @include r.sp {
      padding: 8px 20px;
      display: block;
      width: 100%;
    }
  }

  // .c-table5__th .
  &__th {
    color: #fff;
    font-weight: 500;
    background-color: v.$color10;
    vertical-align: middle;
    letter-spacing: 1px;

    @include r.pc {
      min-width: 200px;
      width: 5%;
    }

    @include r.sp {
      text-align: center;
    }
  }

  &__td {
    letter-spacing: 0.5px;
    background-color: #fff;

    @include r.sp {
      padding-top: 7.5px;
      padding-bottom: 7.5px;
    }
  }

  &__tr {
    @include r.sp {
      display: block;
      text-align: center;
    }
  }

  @include r.sp {
    font-size: 1.2rem;
  }

  &__link {
    border-bottom: 1px solid v.$color27;
    color: v.$color27;
    letter-spacing: 0.6px;

    @include r.hover {
      border-bottom-color: transparent;
    }
  }

  &__flex {
    &__text {
      @include r.pc {
        margin-right: 35px;
      }
    }
  }
}
