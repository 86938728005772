@use '../settings/mixin' as r;
@use '../settings/variable' as v;
@use '../settings/_path' as p;

// @use '../../../node_modules/sanitize.css/sanitize.css';
// @use '../../../node_modules/sanitize.css/forms.css';

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}

li {
  list-style-type: none;
}

th {
  font-weight: inherit;
}

address {
  font-style: inherit;
}

fieldset {
  min-width: 0;
  padding: 0;
  border: 0;
}

a {
  transition: 0.3s ease-in-out;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;

  @include r.hover {
    opacity: 0.8;
    filter: alpha(opacity=80);
    -ms-filter: 'alpha(opacity=80)';
  }

  &[href^='tel:'] {
    @include r.pc {
      pointer-events: none;
    }
  }
}

img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

button,
input,
select,
textarea {
  padding: 0;
  border: 0;
}

button:not(:disabled):not([aria-disabled='true']) {
  cursor: pointer;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Source Han Sans', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #333;

  &.is-fixed {
    @include r.pc {
      overflow: hidden;
    }

    @include r.sp {
      width: 100%;
      position: fixed;
    }
  }
}

select,
input,
textarea {
  box-sizing: border-box;
  border: none;
  background-color: #fff;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  box-shadow: none;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  font-family: Meiryo, 'ＭＳ Ｐゴシック', 'MS P Gothic', Verdana, sans-serif;

  &:focus,
  &:hover {
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
  }
}

button {
  cursor: pointer;
}

select {
  width: 100%;

  &::-ms-expand {
    display: none;
  }
}

input {
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-input-placeholder {
    color: v.$base-color !important;
  }

  &:-ms-input-placeholder {
    color: v.$base-color !important;
  }

  &::placeholder {
    color: v.$base-color !important;
  }

  &::-ms-clear {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

textarea {
  resize: none;
  width: 100%;
  height: 100%;
  padding: 0 0;

  &::-webkit-input-placeholder {
    color: v.$base-color;
  }

  &:-ms-input-placeholder {
    color: v.$base-color;
  }

  &::placeholder {
    color: v.$base-color;
  }
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
  body {
    // Safari用のハック
    height: -webkit-fill-available;
  }
}

[v-cloak] {
  visibility: hidden;
}

.l-wrapper {
  position: relative;
  overflow: hidden;
}

.pc {
  @include r.mq(mdless) {
    display: none !important;
  }
}

.sp {
  @include r.mq(mdover) {
    display: none !important;
  }
}

.c-dev-title1 {
  background: #000;
  color: #fff;
  line-height: 1;
  padding: 6px 0 6px 15px;
  font-size: 1.6rem;
  margin: 60px 0 20px 0;
}

.c-dev-title2 {
  background: #888;
  color: #fff;
  line-height: 1;
  padding: 6px 0 6px 30px;
  font-size: 1.3rem;
  margin: 50px 0 10px 0;
}

.l-container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  @include r.sp {
    max-width: 100%;
    padding: 0 15px;
  }

  .l-container2 {
    max-width: 1120px;
    padding: 0;
  }
}

.l-container2 {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  @include r.clear;
  @include r.sp {
    max-width: 100%;
    padding: 0 15px;
  }
}

.l-container3 {
  max-width: 1020px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
  @include r.sp {
    max-width: 100%;
    padding: 0 25px;
  }
}

.l-container4 {
  max-width: 1170px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 25px;
}

.l-content {
  max-width: calc(100% - 350px);
  width: 750px;
  float: left;
  @include r.sp {
    max-width: 100%;
    width: 100%;
    float: none;
  }
}

main {
  padding-top: 85px;
  padding-bottom: 161px;
  position: relative;

  @include r.sp {
    padding-top: 60px;
    padding-bottom: 81px;
  }
}

/*
------------------------------------------------------------*/
.l-aside {
  padding-top: 87px;
  padding-bottom: 140px;
  width: 100%;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  @include r.pc {
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    align-items: flex-start;
  }

  @include r.max-w(768px) {
    padding-bottom: 60px;
  }

  @include r.sp {
    padding-top: 43px;
    padding-bottom: 0;
  }

  // .l-aside__content .
  &__content {
    @include r.pc {
      flex: 1;
      padding-right: 60px;
    }

    @include r.pad {
      padding-right: 30px;
      width: calc(100% - 300px);
    }

    @include r.sp {
      padding: 0 25px;
      width: 100%;
    }
  }
}

/*
------------------------------------------------------------*/
.l-detail {
  width: 100%;

  // .l-detail .c-text1 .
  .c-text1 {
    padding-bottom: 53px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #ccc;

    @include r.sp {
      padding-bottom: 27px;
    }
  }

  // .l-detail .c-imgtext2 .
  .c-imgtext2 {
    margin-bottom: 116px;

    @include r.max-w(768px) {
      margin-bottom: 60px;
    }

    @include r.sp {
      margin-bottom: 30px;
    }
  }

  .c-title4 {
    margin-bottom: 30px;

    @include r.sp {
      margin-bottom: 30px;
    }
  }

  // .l-detail .c-title1.is-style1 .
  .c-title1.is-style1,
  .c-title1.is-style2 {
    margin-bottom: 23px;

    @include r.sp {
      margin-bottom: 14px;
    }
  }

  // .l-detail .c-socials .
  .c-socials {
    margin-top: -48px;
    padding-top: 40px;
    border-top: 1px solid #ccc;
  }
}

/*
------------------------------------------------------------*/
.l-related {
  &1 {
    padding-top: 80px;

    @include r.sp {
      padding-top: 40px;
    }

    // .l-related1 .c-title1 .
    .c-title1 {
      margin-bottom: 62px;

      @include r.sp {
        margin-bottom: 25px;
      }
    }

    .c-list4__item {
      margin-bottom: 60px;

      @include r.sp {
        margin-bottom: 60px;
      }
    }
  }

  &2 {
    padding-top: 28px;

    @include r.sp {
      padding-top: 41px;
    }

    // .l-related2 .c-title1 .
    .c-title1 {
      margin-bottom: 61px;

      @include r.sp {
        margin-bottom: 31px;
      }
    }

    // .l-related2 .c-btn1 .
    .c-btn1 {
      margin-top: 10px;

      @include r.sp {
        margin-top: 20px;
      }
    }
  }
}
