@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
other
------------------------------------------------------------*/
.c-box1 {
  @include r.sp {
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
  }

  > .l-container {
    @include r.sp {
      padding: 0;
    }
  }

  // .c-box1__text .
  &__text {
    padding: 13px 10px;
    letter-spacing: 1.5px;
    position: relative;
    z-index: 1;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 500;
    color: #fff;
    background-color: v.$color10;
    display: inline-block;
    min-width: 160px;
    text-align: center;

    @include r.sp {
      padding: 9px 5px 10px;
      min-width: 120px;
      font-size: 1.4rem;
    }
  }

  // .c-box1__list .
  &__list {
    margin-top: -20px;
    padding: 15px 41px 11px;
    position: relative;
    z-index: 0;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;

    @include r.sp {
      margin-top: -14px;
      padding: 22px 0 15px;
      align-items: flex-end;
      justify-content: space-between;
    }

    @include r.calc(767, padding-right, 13, 375);
    @include r.calc(767, padding-left, 13, 375);
  }

  // .c-box1__item .
  &__item {
    @include r.pc {
      display: flex;
      align-items: center;
    }

    @include r.sp {
      margin-bottom: 12px;
    }

    &:first-child {
      @include r.sp {
        margin-right: 0 !important;
      }
    }

    // .c-box1__item:not(:last-child) .
    &:not(:last-child) {
      margin-right: 15px;

      @include r.pad {
        margin-right: 7%;
      }

      @include r.sp {
        margin-right: 3%;
      }

      @include r.calc(767, margin-right, 17, 375);
    }

    &:nth-child(2n) {
      @include r.sp {
        // margin-right: 0;
      }
    }

    &:nth-child(2) {
      @include r.sp {
        margin-right: 0;
      }
    }

    &:nth-child(3n) {
      @include r.sp {
        margin-right: 0;
      }
    }

    &:last-child {
      @include r.sp {
        margin-right: auto;
      }
    }

    img {
      position: relative;
    }

    &.is-logo1 {
      img {
        @include r.pc {
          top: 8px;
        }

        @include r.calc(767, width, 57, 375);
      }
    }

    &.is-logo2 {
      // .c-box1__item.is-logo2 img .
      img {
        @include r.pc {
          top: 8px;
        }

        @include r.sp {
          top: -5px;
        }

        @include r.calc(767, width, 104, 375);
      }
    }

    &.is-logo3 {
      // .c-box1__item.is-logo3 img .
      img {
        @include r.sp {
          top: -2px;
        }

        @include r.calc(767, width, 76, 375);
      }
    }

    &.is-logo4 {
      // .c-box1__item.is-logo4 img .
      img {
        @include r.calc(767, width, 80, 375);
      }
    }

    &.is-logo5 {
      // .c-box1__item.is-logo5 img .
      img {
        @include r.sp {
          top: 2px;
        }

        @include r.calc(767, width, 120, 375);
      }
    }

    &.is-logo6 {
      // .c-box1__item.is-logo6 img .
      img {
        @include r.sp {
          top: -4px;
        }

        @include r.calc(767, width, 89, 375);
      }
    }

    &.is-logo7 {
      // .c-box1__item.is-logo7 img .
      img {
        @include r.calc(767, width, 63, 375);
      }
    }

    &.is-logo8 {
      // .c-box1__item.is-logo8 img .
      img {
        @include r.calc(767, width, 41, 375);
      }
    }

    &.is-logo3,
    &.is-logo4,
    &.is-logo5,
    &.is-logo6 {
      img {
        @include r.pc {
          top: -2px;
        }
      }
    }

    &.is-logo7 {
      img {
        @include r.pc {
          top: -4px;
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-block1 {
  position: relative;

  @include r.sp {
    padding-left: 25px;
  }

  > .l-container {
    @include r.sp {
      padding: 0;
    }
  }

  // .c-block1__btn .
  &__btn {
    @include r.sp {
      padding-right: 9px;
    }
  }

  &__inner {
    background-color: #fff;
    overflow: hidden;

    @include r.pc {
      position: relative;
    }

    @include r.max-w(1350px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    @include r.sp {
      padding-left: 0;
      padding-right: 15px;
    }

    // .c-block1__inner > .l-container2 .
    > .l-container2 {
      @include r.pc {
        // padding-right: 20px;
      }

      @include r.pad {
        padding-right: 0;
      }

      @include r.sp {
        // padding-right: 25px;
      }
    }
  }

  &__row {
    @include r.pc {
      display: flex;
      align-items: flex-start;
    }
  }

  // .c-block1__content .
  &__content {
    padding-top: 62px;
    margin-bottom: 40px;

    @include r.pc {
      flex: 1;
      max-width: calc(100% - 200px);
    }

    @include r.max-w(1350px) {
      max-width: calc(100% - 205px);
    }

    @include r.sp {
      padding: 22px 0 0 19px;
      margin-right: -12px;
      margin-bottom: 29px;
      max-width: 100%;
    }
  }

  // .c-block1__title .
  &__title {
    padding: 17px 10px 0 0;
    width: 100%;

    @include r.pc {
      position: relative;
      margin-left: -5px;
      max-width: 205px;
    }

    @include r.max-w(1350px) {
      max-width: unset;
      width: 205px;
    }

    @include r.sp {
      padding: 9px 10px 0 11px;
      width: 100%;
    }

    // .c-block1__title:after .
    &:after {
      left: -75.4%;
      width: 97.08%;
      top: 0;
      height: 114px;
      content: '';
      position: absolute;
      z-index: 0;
      background-color: v.$color4;

      @include r.sp {
        width: 59px;
        height: 60px;
        left: 0;
      }
    }

    // .c-block1__title__text .
    &__text {
      padding-top: 22px;
      width: max-content;
      position: relative;
      z-index: 1;
      color: v.$color11;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include r.sp {
        padding-top: 10px;
      }

      // .c-block1__title__text:after .
      &:after {
        width: 100%;
        height: 2px;
        left: 5px;
        top: 0;
        content: '';
        background-color: #fff;
        position: absolute;

        @include r.sp {
          height: 1px;
          left: 3px;
        }
      }
    }

    // .c-block1__title__jp .
    &__jp {
      letter-spacing: 1.4px;
      margin-top: 5px;
      padding-right: 6px;
      font-size: 1.4rem;
      line-height: 1;
      font-weight: 500;

      @include r.sp {
        padding-right: 2px;
        margin-top: 0;
        margin-top: 5px;
        font-size: 1.2rem;
      }
    }

    &__en {
      letter-spacing: 4px;
      @include r.josefin;
      text-transform: uppercase;
      font-size: 4.8rem;
      line-height: 1;
      font-weight: normal;

      @include r.sp {
        letter-spacing: 2px;
        font-size: 2.4rem;
      }
    }
  }

  .c-btn2 {
    margin-left: auto;

    @include r.sp {
      margin-right: auto;
    }
  }
}

/*
------------------------------------------------------------*/
.c-block2 {
  padding-top: 61px;
  padding-bottom: 57px;
  background: v.$color4 url(p.$path + 'images/top/bg-block2.png') no-repeat
    bottom -23px right 129px;

  @include r.max-w(1679px) {
    background-position: bottom -23px right 40px;
  }

  @include r.max-w(1399px) {
    background-position: center right;
    @include r.calc(1399, padding-top, 55, 1399);
    @include r.calc(1399, padding-bottom, 55, 1399);
  }

  @include r.max-w(1520px) {
    background-size: 36vw auto;
  }

  @include r.pad {
    background-size: 45vw auto;
  }

  @include r.sp {
    padding-top: 31px;
    padding-bottom: 30px;
    background-size: 328px auto;
    background-position: bottom -24px right -139px;
  }

  @include r.max-w(374px) {
    background-size: 286px auto;
  }

  // .c-block2__title .
  &__title {
    margin-bottom: 3px;
    letter-spacing: 3.7px;
    font-size: 4.8rem;
    line-height: 5.1rem;
    color: #fff;
    @include r.josefin;
    text-transform: uppercase;

    @include r.sp {
      letter-spacing: 2px;
      margin-bottom: -1px;
      font-size: 2.4rem;
      line-height: 2.55rem;
      width: 100%;
      text-align: center;
    }
  }

  // .c-block2__row .
  &__row {
    margin-top: 30px;

    @include r.pc {
      padding-left: 4px;
      display: flex;
      align-items: flex-start;
    }

    @include r.max-w(1199px) {
      width: 63%;
    }

    @include r.pad {
      width: 45%;
      padding-left: 0;
      display: block;
    }

    @include r.sp {
      width: 100%;
      margin-top: 9px;
    }

    // .c-block2__row .c-block2__text .
    .c-block2__text {
      @include r.sp {
        max-width: 50%;
      }

      @include r.max-w(414px) {
        max-width: 60%;
      }

      @include r.max-w(374px) {
        max-width: 66%;
      }
    }
  }

  // .c-block2__text .
  &__text {
    font-size: 1.4rem;
    line-height: 1.7;
    color: #fff;
    letter-spacing: 0.85px;

    @include r.sp {
      line-height: 1.72;
      letter-spacing: 0.85px;
      font-size: 1.2rem;
      font-weight: 500;
      width: 100%;
    }

    &.is-style1 {
      font-weight: 500;
      letter-spacing: 0;

      @include r.sp {
        text-align: center;
      }
    }

    // .c-block2__text + .c-btn2 .
    & + .c-btn2 {
      @include r.pc {
        top: 1px;
        position: relative;
        margin-left: 21px;
      }

      @include r.pad {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-tag1 {
  display: inline-block;
  text-align: center;
  background: #fff;
  border: 1px solid v.$color15;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.2rem;
  line-height: 1.3;
  color: v.$text-color;
  font-weight: 500;
  padding: 6px 7px;
  @include r.sp {
    font-size: 9px;
    padding: 5px 5.5px;
    border-radius: 2px;
  }
}

/*
------------------------------------------------------------*/
.c-tag2 {
  width: 120px;
  text-align: center;
  background: v.$color4;
  border-radius: 0 4px 4px 0;
  font-size: 1.4rem;
  line-height: 1.2;
  color: v.$color16;
  font-weight: 500;
  letter-spacing: 1.5px;
  padding: 6px 3px 6px 7px;
  @include r.max-w(767px) {
    padding: 4px 3px 4px 7px;
    font-size: 1.2rem;
    width: 84px;
  }
  @include r.sp {
    padding: 5px 5px 5px 7px;
    width: 98px;
    border-radius: 0 2px 2px 0;
    letter-spacing: 1px;
  }
}

/*
------------------------------------------------------------*/
.c-tag3 {
  text-align: center;
  background: v.$color13;
  border-radius: 4px;
  font-size: 1.2rem;
  line-height: 1.2;
  color: v.$text-color;
  padding: 5px 9px 4px 12px;
  @include r.max-w(768px) {
    padding-left: 9px;
  }
  @include r.sp {
    border-radius: 2px;
    font-size: 1rem;
    padding: 4px 7px 4px 8px;
  }
}

/*
------------------------------------------------------------*/
.c-block3 {
  $root: &;

  + #{$root} {
    margin: 39px 0 0;
    @include r.sp {
      margin: 29px 0 0;
    }
  }

  //-----------------------------------------------------
  &__ttl {
    font-size: 1.6rem;
    line-height: 1.5;
    color: v.$color8;
    font-weight: 500;
    letter-spacing: 0.8px;
    margin: 0 0 28px 19px;
    @include r.sp {
      font-size: 1.4rem;
      margin: 0 0 28px 4px;
    }
  }

  //-----------------------------------------------------
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include r.sp {
      display: block;
    }
  }

  //-----------------------------------------------------
  &__map {
    width: 50%;
    height: 310px;
    @include r.sp {
      width: 100%;
      height: 55vw;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  //-----------------------------------------------------
  &__info {
    width: 47.3%;
    margin: -8px 0 0;
    @include r.sp {
      width: 100%;
      margin: 10px 0 0;
    }
  }

  //-----------------------------------------------------
  &__txt {
    font-size: 1.6rem;
    line-height: 1.7;
    color: v.$text-color;
    letter-spacing: 0.8px;
    @include r.sp {
      font-size: 1.1rem;
      line-height: 1.65;
      letter-spacing: 1px;
    }

    &:not(:last-child) {
      margin: 0 0 27px;
      @include r.sp {
        margin: 0 0 17px;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-faq {
  $root: &;

  + #{$root} {
    margin: 10px 0 0;
  }

  &__icon {
    right: 10px;
    pointer-events: none;
    top: calc(50% - 7px);
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #333;

    @include r.sp {
      right: 5px;
      width: 16px;
      height: 16px;
    }

    &:before,
    &:after {
      content: '';
      background-color: #333;
      position: absolute;
    }

    &:before {
      top: 6px;
      left: 2px;
      width: 10px;
      height: 2px;

      @include r.sp {
        width: 8px;
        top: 5px;
      }
    }

    &:after {
      top: 2px;
      left: 6px;
      height: 10px;
      width: 2px;
      transition: opacity 0.3s ease-in-out;

      @include r.sp {
        height: 8px;
        left: 5px;
      }
    }
  }

  //-----------------------------------------------------
  // .c-faq__heading .
  &__heading {
    background: #fff;
    position: relative;
    padding: 7px 42px 11px 8px;
    cursor: pointer;
    @include r.sp {
      padding: 9px 28px 9px 5px;
    }

    &.is-open {
      .c-faq__icon {
        &:after {
          opacity: 0;
        }
      }
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;
  }

  //-----------------------------------------------------
  &__ttl {
    min-height: 42px;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.5;
    color: v.$color11;
    font-weight: 500;
    letter-spacing: 1px;
    position: relative;
    padding: 0 0 0 50px;
    @include r.sp {
      min-height: 22px;
      font-size: 1.2rem;
      letter-spacing: 0.5px;
      padding: 0 0 0 33px;
    }

    &::before {
      content: 'Q';
      width: 40px;
      height: 40px;
      background: v.$color4;
      text-align: center;
      font-size: 1.8rem;
      line-height: 40px;
      font-weight: 500;
      color: #fff;
      position: absolute;
      top: 3px;
      left: 0;
      @include r.sp {
        width: 22.5px;
        height: 22.5px;
        font-size: 1.2rem;
        line-height: 22.5px;
        top: 0;
      }
    }
  }

  //-----------------------------------------------------
  &__info {
    display: none;
    background: #fff;
    margin: 2px 0 0;
    position: relative;
    padding: 5px 30px 5px 79px;
    @include r.sp {
      margin: 0;
      padding: 13px 20px 12px 46px;
    }

    &::before {
      content: 'A';
      width: 40px;
      height: 40px;
      background: v.$color12;
      text-align: center;
      font-size: 1.8rem;
      line-height: 40px;
      font-weight: 500;
      color: #fff;
      position: absolute;
      top: 10px;
      left: 28px;
      @include r.sp {
        width: 22.5px;
        height: 22.5px;
        font-size: 1.2rem;
        line-height: 22.5px;
        top: 15px;
        left: 13px;
      }
    }
  }

  //-----------------------------------------------------
  &__txt {
    word-break: break-all;
    font-size: 1.4rem;
    line-height: 1.5;
    color: v.$color11;
    letter-spacing: 0.5px;
    @include r.sp {
      font-size: 1.2rem;
      letter-spacing: 0.3px;
    }
  }

  //-----------------------------------------------------
  // .c-faq__date .
  &__date {
    margin-top: 3px;
    text-align: right;
    font-size: 1.2rem;
    color: v.$color11;
    @include r.sp {
      font-size: 1rem;
    }
  }
}

/*
------------------------------------------------------------*/
.c-banner1 {
  border: 10px solid v.$color5;
  position: fixed;
  z-index: 98;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  width: 300px;
  background: url(p.$path + 'images/common/bg-banner1.jpg') no-repeat center
    left / cover;

  @include r.pc {
    padding: 25px 8px 14px;
    bottom: 20px;
    right: 100px;
    min-width: max-content;
  }

  @include r.max-w(1200px) {
    right: 40px;
  }

  @include r.calc(1024, border-width, 10, 1024);
  @include r.calc(1024, width, 300, 1024);

  @include r.pad {
    right: 20px;
  }

  @include r.sp {
    bottom: 0;
    left: 0;
    right: auto;
    background-image: url(p.$path + 'images/common/bg-banner1-sp.jpg');
    display: flex;
    align-items: stretch;
    width: 100%;
    border-width: 5px;
  }

  &.is-absolute {
    @include r.pc {
      bottom: 20px;
      // top: -250px;
      position: absolute;
      // bottom: auto;
    }
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  // .c-banner1__grtext .
  &__grtext {
    @include r.sp {
      padding: 9px 10px 8px;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  // .c-banner1__btn .
  &__btn {
    background: linear-gradient(to bottom, v.$color6 50%, v.$color7 50%);
    overflow: hidden;

    @include r.pc {
      margin-top: 26px;
      box-shadow: 1px 0 2px rgba($color: #000, $alpha: 0.28);
      border-radius: 4px;
    }

    @include r.calc(1024, margin-top, 26, 1024);

    @include r.sp {
      width: 90px;
      margin-top: 0;
    }

    // .c-banner1__btn__text .
    &__text {
      padding: 16px 10px;
      color: #fff;
      font-size: 1.8rem;
      line-height: 1;
      font-weight: 700;
      text-align: center;
      text-shadow: 1px 0 2px rgba($color: #000, $alpha: 0.28);
      width: 100%;
      display: block;

      @include r.calc(1024, font-size, 18, 1024);

      @include r.sp {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
      }
    }
  }

  // .c-banner1__text2 .
  &__text2 {
    letter-spacing: -1.7px;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.5;
    width: 100%;

    @include r.calc(1024, font-size, 18, 1024);

    @include r.sp {
      letter-spacing: -0.7px;
      font-size: 1.1rem;
    }
  }

  // .c-banner1__text1 .
  &__text1 {
    margin-bottom: 11px;
    font-weight: 900;
    line-height: 1;
    color: v.$color8;
    text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff,
      -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
    letter-spacing: -2px;
    transition: 0.3s ease-in-out;

    @include r.pc {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @include r.sp {
      margin-bottom: 2px;
      background: linear-gradient(transparent 50%, v.$color9 50%);
    }

    @include r.max-w(374px) {
      background: linear-gradient(transparent 60%, v.$color9 40%);
    }

    &__top {
      font-size: 2.5rem;

      @include r.pc {
        background: linear-gradient(transparent 70%, v.$color9 30%);
      }

      @include r.calc(1024, font-size, 30, 1024);

      @include r.sp {
        font-size: 1.8rem;
      }

      @include r.max-w(374px) {
        font-size: 1.6rem;
      }
    }

    // .c-banner1__text1__bottom .
    &__bottom {
      font-size: 3.2rem;

      @include r.pc {
        letter-spacing: -3.7px;
        margin-top: 8px;
        background: linear-gradient(transparent 70%, v.$color9 30%);
      }

      @include r.calc(1024, font-size, 39, 1024);

      @include r.sp {
        font-size: 2.4rem;
      }

      @include r.max-w(374px) {
        font-size: 2rem;
      }
    }
  }

  // .c-banner1__close .
  &__close {
    cursor: pointer;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #000;
    top: -20px;
    right: -20px;
    z-index: 2;
    position: absolute;

    @include r.hover {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -ms-filter: 'alpha(opacity=80)';
    }

    @include r.pad {
      right: -15px;
    }

    @include r.sp {
      top: -15px;
      right: -5px;
    }

    // .c-banner1__close:before, .c-banner1__close:after .
    &:before,
    &:after {
      top: 7px;
      left: 3px;
      content: '';
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: #000;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

/*
------------------------------------------------------------*/
.c-banner2 {
  padding: 50px 20px;
  width: 100%;
  background: url(p.$path + '/images/common/bg-banner2.png') no-repeat top 66%
      left 7.8%,
    url(p.$path + '/images/common/bg2-banner2.jpg') no-repeat center / cover;

  @include r.pad {
    background: url(p.$path + '/images/common/bg-banner2.png') no-repeat top 75%
        left / 50%,
      url(p.$path + '/images/common/bg2-banner2.jpg') no-repeat center / cover;
  }

  @include r.max-w(768px) {
    background: url(p.$path + '/images/common/bg-banner2.png') no-repeat top 85%
        left / 40%,
      url(p.$path + '/images/common/bg2-banner2.jpg') no-repeat center / cover;
  }

  @include r.sp {
    padding: 56px 20px;
    background: url(p.$path + '/images/common/bg-banner2.png') no-repeat top 78%
        left calc((-179 / 375) * 100vw) / 88.5%,
      url(p.$path + '/images/common/bg2-banner2-sp.jpg') no-repeat center /
        cover;
  }

  .c-btn1 {
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    @include r.pad {
      max-width: 500px;
    }

    @include r.max-w(768px) {
      max-width: 400px;
    }

    @include r.sp {
      max-width: 276px;
    }

    // .c-banner2 .c-btn1__txt .
    &__txt {
      letter-spacing: 1px;
      padding-top: 27px;
      padding-bottom: 29px;
      width: 100%;
      font-size: 2.4rem;
      line-height: 1;
      background: linear-gradient(to bottom, v.$color22 50%, v.$color23 50%);

      @include r.max-w(768px) {
        font-size: 1.8rem;
      }

      @include r.sp {
        padding-top: 11px;
        padding-bottom: 12px;
        font-size: 1.4rem;
        line-height: 2.05rem;
      }
    }
  }

  // .c-banner2.is-style1 .
  &.is-style1 {
    padding: 60px 20px;

    @include r.sp {
      padding: 35px 20px;
    }

    .c-btn1 {
      max-width: 300px;

      @include r.sp {
        max-width: 235px;
      }

      // .c-banner2.is-style1 .c-btn1__txt .
      &__txt {
        padding: 21px 0;
        font-size: 1.8rem;
        line-height: 1;

        @include r.sp {
          padding: 14.5px 0;
          font-size: 1.4rem;
        }
      }
    }
  }

  &__btns {
    display: flex;
    justify-content: center;

    @include r.sp {
      flex-direction: column;
      align-items: center;
    }

    .c-btn1 {
      margin: 0;

      // .c-banner2__btns .c-btn1 + .c-btn1 .
      & + .c-btn1 {
        @include r.pc {
          margin-left: 30px;
        }

        @include r.sp {
          margin-top: 20px;
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-socials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &__img {
    width: 100%;
    display: block;
    border-radius: 50%;
    overflow: hidden;
  }

  &__item {
    margin: 0 11px 20px;
    width: 100%;
    max-width: 52px;
  }
}

/*
------------------------------------------------------------*/
#tinymce.post-type-blog,
#tinymce.post-type-works,
.wp-content {
  padding: 68px 0 101px;

  @include r.sp {
    padding: 64px 0 37px;
    border-top-style: dotted;
    position: relative;
  }

  // .wp-content h3 .
  h3 {
    padding-left: 14px;
    letter-spacing: 1.1px;
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: 500;
    color: v.$color11;
    position: relative;
    margin-top: 60px;
    @include r.sp {
      font-size: 1.8rem;
    }

    // .wp-content h3:before .
    &:before {
      top: -8px;
      left: 2px;
      content: '';
      position: absolute;
      z-index: -1;
      width: 33px;
      height: 33px;
      background-color: v.$color4;

      @include r.sp {
        top: -24px;
        left: -25px;
        width: 60px;
        height: 60px;
      }
    }

    // .wp-content h3:after .
    &:after {
      left: 16px;
      top: 2px;
      width: 19px;
      height: 1px;
      background-color: #fff;
      content: '';
      position: absolute;
      z-index: -1;

      @include r.sp {
        top: -9px;
        left: 15px;
        width: 40px;
        background-color: v.$color11;
      }
    }
  }

  h4 {
    font-size: 120%;
    font-weight: bold;
  }

  h5 {
    font-weight: bold;
  }

  ol {
    counter-reset: ol;

    // .wp-content ol li:before .
    li {
      font-size: 1.6rem;
      line-height: 1.7;
      display: flex;

      @include r.sp {
        font-size: 1.4rem;
        line-height: 1.25;
      }

      &:not(:last-child) {
        @include r.sp {
          margin-bottom: 7px;
        }
      }

      &:before {
        letter-spacing: 2px;
        margin-right: 5px;
        counter-increment: ol;
        content: counter(ol) '.';
        width: max-content;
      }
    }
  }

  ul {
    // .wp-content ul li .
    li {
      padding-left: 18px;
      font-size: 1.6rem;
      line-height: 1.7;
      position: relative;

      @include r.sp {
        padding-left: 12px;
        line-height: 1.25;
        font-size: 1.4rem;
      }

      // .wp-content ul li:not(:last-child) .
      &:not(:last-child) {
        margin-bottom: 7px;

        @include r.sp {
          margin-bottom: 8px;
        }
      }

      // .wp-content ul li:before .
      &:before {
        top: 11px;
        left: 2px;
        background-color: v.$color10;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;

        @include r.sp {
          top: 5px;
          left: 1px;
          width: 7px;
          height: 7px;
        }
      }
    }

    & + ol {
      margin-top: 46px;
    }
  }

  // .wp-content p .
  p {
    font-size: 1.6rem;
    line-height: 1.7;

    @include r.sp {
      font-size: 1.4rem;
    }

    + ul {
      margin-top: 39px;

      @include r.sp {
        margin-top: 42px;
      }
    }
  }

  a {
    text-decoration: underline;
  }

  pre {
    font-size: 1.4rem;
    line-height: 1.7;
    letter-spacing: 0.4px;
    display: block;
    white-space: pre-line;
    @include r.sourcehansans();

    @include r.sp {
      font-size: 1rem;
    }

    // .wp-content small + ul .
    + ul {
    }

    &.text-right-pc {
      @include r.pc {
        text-align: right;
      }
    }
  }

  // .wp-content .c-detail-box2 .
  .c-detail-box2 {
    padding: 14px 20px 15px;
    background-color: v.$color13;

    @include r.sp {
      padding: 30px 15px 35px;
    }

    p {
      margin-bottom: 0;
      font-size: 1.4rem;
      line-height: 1.7;

      @include r.sp {
        font-size: 1.2rem;
      }
    }
  }

  // .wp-content table .
  table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    @include r.sp {
      // width: calc(100% - 45px);
      margin: 0 auto;
    }

    th,
    td {
      vertical-align: middle;
      font-size: 1.4rem;
      line-height: 1.7;

      @include r.pc {
        border: 2px solid #fff;
      }

      @include r.sp {
        font-size: 1.2rem;
        border: 1px solid #fff;
      }

      p {
        font-size: 1.4rem;
        margin-bottom: 0;

        @include r.sp {
          font-size: 1.2rem;
        }
      }
    }

    th {
      color: #fff;
      background-color: v.$color10;
    }

    td {
      background-color: v.$color13;
    }

    thead {
      // @include r.sp {
      //   display: none;
      // }

      th {
        padding: 18px 23px;
        @include r.sp {
          padding: 10px;
        }

        &:first-child {
          @include r.sp {
            padding: 0 20px;
          }
        }
      }
    }

    tbody {
      // .wp-content table tbody th .
      th {
        padding: 19px 19px 17px;
        font-weight: 500;

        // @include r.pc {
        //   // min-width: 142px;
        //   width: 18.8%;
        // }

        @include r.sp {
          // margin-bottom: 3px;
          padding: 8px 5px 7px;
          // display: block;
          // text-align: center;
        }
      }

      // .wp-content table tbody th, .wp-content table tbody td .
      th,
      td {
        // @include r.sp {
        //   width: 100%;
        // }
      }

      // .wp-content table tbody td .
      td {
        padding: 18px 20px;
        width: 40%;

        // @include r.pc {
        //   width: 40.6%;
        //   // min-width: 308px;
        // }
        @include r.sp {
          padding: 9px 7px;
        }
        @include r.max-w(350px) {
          padding: 9px 5px;
        }
        // @include r.sp {
        //   margin-bottom: 3px;
        //   padding: 7px 9px;
        //   display: flex;
        //   align-items: center;
        // }

        .sp {
          // @include r.sp {
          //   padding-right: 5px;
          //   padding-bottom: 1px;
          //   min-width: 33px;
          //   font-weight: 500;
          //   color: v.$color10;
          //   width: max-content;
          // }
        }
      }

      tr {
        // @include r.sp {
        //   display: block;
        // }

        &:last-child {
          td {
            &:last-child {
              @include r.sp {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    &.overview {
      tr {
        td {
          width: auto;
          border: none;
          &:nth-of-type(1) {
            max-width: 1%;
            white-space: nowrap
          }
          &:nth-of-type(2) {
            max-width: 1%;
            white-space: nowrap
          }
          &:nth-of-type(3) {
            width: 100%;
          }
        }
      }
    }
  }
  iframe{
    width: 800px;
    height: 430px;
    @include r.sp{
      height: 56.25vw;
      width: 100%;
    }
  }
  .tb__scroll{
    @include r.sp{
      width: 100%;
      overflow: auto;
      table{
        width:1000px;
      }
    }
  }
}

.wp-content {
  &.is-works {
    .aligncenter {
      display: block;
      margin: 0 auto;
      @include r.sp {
        margin: 10px auto;
      }
    }

    .alignleft {
      float: left;
      margin: 5px 20px 5px 0;
      @include r.sp {
        margin: 10px auto;
        display: block;
        float: none;
      }
    }

    .alignright {
      float: right;
      margin: 5px 0 5px 20px;
      @include r.sp {
        margin: 10px auto;
        display: block;
        float: none;
      }
    }
  }

  .logo {
    max-width: 600px;
    height: 200px;
    margin: auto;
    @include r.sp {
      height: 100px;
    }
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

/*
------------------------------------------------------------*/
.c-related {
  padding-top: 83px;

  @include r.sp {
    padding-top: 40px;
  }

  .c-title1 {
    margin-bottom: 61px;

    @include r.sp {
      margin-bottom: 30px;
    }
  }

  // .c-related .c-list3 .
  .c-list3 {
    margin-bottom: 10px;

    @include r.sp {
      margin-bottom: 31px;
    }
  }

  .c-list4 {
    margin-bottom: 22px;

    @include r.sp {
      margin-bottom: 24px;
    }

    &__item {
      @include r.sp {
        margin-top: 5px !important;
      }
    }

    .c-tag2 {
      @include r.sp {
        padding: 8px 7px;
        width: 96px;
      }
    }
  }

  .c-btn1 {
    // margin-top: 10px;

    @include r.sp {
      // margin-top: 30px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-box3 {
  @include r.sp {
    padding: 0 10px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
    @include r.sp {
      flex-direction: column;
      margin-top: 9px;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    @include r.sp {
      border-radius: 5px;
    }
  }

  &__video {
    width: 50%;
    height: 380px;
    @include r.sp {
      height: 200px;
      width: 100%;
      padding: 0 15px;
      margin-top: 37px;
    }
  }

  .c-text1 {
    width: 45%;
    @include r.sp {
      width: 100%;
      letter-spacing: -0.4px;
    }
  }
}

/*
------------------------------------------------------------*/
.wp-pagenavi {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  // .wp-pagenavi a, .wp-pagenavi span .
  a,
  span {
    padding-bottom: 2px;
    margin: 0 5.5px;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 500;
    width: 24px;
    height: 24px;
    transition: 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    @include r.sp {
      font-size: 1.4rem;
      width: 27px;
      height: 27px;
    }
  }

  a {
    color: #333;

    @include r.hover {
      background-color: v.$color29;
      color: #fff;
    }
  }

  span {
    color: #fff;
    background-color: v.$color29;
  }

  .previouspostslink,
  .nextpostslink {
    // position: relative;
    font-size: 0;
    color: transparent;
    width: 11px;
    height: 11px;
    background: url(p.$path + '/images/common/icon-arrow-pagi.png') no-repeat
      center / contain;

    @include r.sp {
      width: 12px;
      height: 12px;
    }

    &:before {
      // content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      // border-top: 2px solid v.$color28;
    }

    @include r.hover {
      background-color: transparent;
      background-image: url(p.$path + '/images/common/icon-arrow-pagi-hover.png');
    }
  }

  .previouspostslink {
    margin-left: 0;
    margin-right: 22.5px;
    transform: rotate(180deg);

    @include r.sp {
      margin-right: 27px;
    }

    &:before {
      // border-left: 2px solid v.$color28;
      // transform: rotate(180deg);
    }
  }

  .nextpostslink {
    margin-left: 22.5px;
    margin-right: 0;

    @include r.sp {
      margin-left: 27px;
    }

    &:before {
      // border-right: 2px solid v.$color28;
      // transform: rotate(45deg);
    }
  }

  .first,
  .last {
    display: none;
  }
}

/*
------------------------------------------------------------*/
.c-box4 {
  $root: &;
  padding: 0 90px 0 70px;
  @include r.sp {
    padding: 0;
  }

  &__inner {
    display: flex;
    @include r.sp {
      flex-wrap: wrap;
      padding: 0 10px;
    }
  }

  &__item {
    width: 54%;
    position: relative;
    @include r.sp {
      width: 100%;
    }

    &:last-child {
      padding-left: 43px;
      @include r.sp {
        padding-left: 0;
      }

      #{$root}__box {
        background-color: #34d9c6;

        &:after {
          border-top: 10px solid #34d9c6;
        }
      }

      #{$root}__list {
        @include r.sp {
          padding-bottom: 10px;
        }
      }

      #{$root}__tlt {
        margin-top: 10px;
        letter-spacing: -0.3px;
        @include r.sp {
          margin-top: 7px;
        }
      }
    }

    &:first-child {
      #{$root}__content {
        background: url(p.$path + 'images/reception/img06.jpg') no-repeat right
          center;
        background-size: cover;
        padding-right: 140px;
        @include r.sp {
          padding-right: 0;
          background: url(p.$path + 'images/reception/img06-sp.jpg') no-repeat
            center bottom;
          background-size: 100% auto;
          margin-bottom: 54px;
        }
      }
    }

    &:last-child {
      width: 44%;
      @include r.sp {
        width: 100%;
      }
    }
  }

  &__content {
    background-color: #eff4fa;
  }

  &__box {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
    border-radius: 50%;
    background-color: #ffbf00;
    padding: 20px 5px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;
    @include r.sp {
      width: 55px;
      height: 55px;
      font-size: 1.6rem;
    }

    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #ffbf00;
      position: absolute;
      bottom: -4px;
    }
  }

  &__tlt {
    color: #16c1ae;
    text-align: center;
    margin: 20px 0 0;
    font-size: 2rem;
    font-weight: bold;
    @include r.sp {
      font-size: 1.8rem;
      margin: 12px 0 0;
      letter-spacing: 1px;
    }

    span {
      font-size: 2.7rem;
      @include r.sp {
        font-size: 2.3rem;
      }
    }
  }

  &__list {
    padding: 0 20px 20px;
    @include r.sp {
      padding: 0 20px 30vw;
      margin-top: -10px;
    }
    @include r.max-w(375px) {
      padding: 0 20px 28vw;
    }
  }

  &__txt {
    text-align: left;
    padding: 8px 10px;
    background-color: #ffffff;
    margin-bottom: 20px;
    font-size: 1.6rem;
    font-weight: 400;
    @include r.sp {
      text-align: left;
      font-size: 1.4rem;
      padding: 4px 10px;
      margin-bottom: 19px;
    }
  }

  &__wrap {
    position: relative;
    top: -36px;
    padding-right: 20px;
    @include r.sp {
      padding-right: 0;
      top: -29px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-box5 {
  width: 100%;
  max-width: 1440px;
  padding: 0 25px;
  margin-left: auto;
  margin-right: auto;

  &__text {
    max-width: 720px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  // .c-box5 .c-title2 .
  .c-title2 {
    margin-bottom: 25px;

    @include r.sp {
      margin-bottom: 9px;
      letter-spacing: 1.6px;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }

    span {
      @include r.sp {
        margin-top: 3px;
        letter-spacing: 0.3px;
      }
    }
  }

  // .c-box5 .c-title5 .
  .c-title5 {
    margin-bottom: 24px;

    @include r.sp {
      margin-bottom: 8px;
    }
  }
}
