@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
mv
------------------------------------------------------------*/
.c-mv1 {
  position: relative;

  // .c-mv1::after .
  &::after {
    content: '';
    width: 100%;
    height: 65.5%;
    min-height: 467px;
    background: v.$color13;
    position: absolute;
    left: 0;
    bottom: 0;
    @include r.sp {
      height: 48.5%;
      min-height: auto;
    }
  }

  //-----------------------------------------------------
  // .c-mv1__inner .
  &__inner {
    width: calc(100% - ((100% - 1440px) / 2));
    max-width: calc(100% - 20px);
    margin: 0 0 0 auto;
    padding: 0 0 0 160px;
    position: relative;

    @include r.max-w(768px) {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
    }
  }

  //-----------------------------------------------------
  &__slide {
    width: 100%;

    // .c-mv1__slide .swiper-pagination .
    .swiper-pagination {
      max-width: 1309px;
      padding: 0 30px;
      position: relative;
      bottom: 0;
      text-align: right;
      font-size: 0;
      margin: 22px 0 0;
      @include r.sp {
        max-width: 100%;
        padding: 0 15px;
        margin: 10px 0 0;
      }

      .swiper-pagination-bullet {
        margin: 0 0 0 10px;
        background: #fff;
        opacity: 1;
        @include r.sp {
          width: 6px;
          height: 6px;
          margin: 0 0 0 7.5px;
        }

        &.swiper-pagination-bullet-active {
          background: v.$color7;
        }
      }
    }
  }

  //-----------------------------------------------------
  &__wrap {
    min-height: 600px;
    @include r.sp {
      min-height: auto;
    }
  }

  //-----------------------------------------------------
  &__item {
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  //-----------------------------------------------------
  // .c-mv1__info .
  &__info {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    transform: translate(0, -50%);
    margin: 1.25% 0 0;

    @include r.max-w(768px) {
      padding-left: 20px;
    }

    @include r.sp {
      margin: 2.2% 0 0 0;
      padding: 0 0 0 10px;
    }
  }

  //-----------------------------------------------------
  &__tag {
    width: 172px;
    display: inline-block;
    background-color: v.$color12;
    border-radius: 30px;
    text-align: center;
    font-size: 3rem;
    line-height: 1.2;
    color: #fff;
    font-weight: bold;
    letter-spacing: 3px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.28);
    padding: 10px 5px 14px 17px;
    position: relative;
    @include r.sp {
      width: 86px;
      font-size: 1.5rem;
      padding: 5px 2px 7px 8px;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 11px 0 11px;
      border-color: v.$color12 transparent transparent transparent;
      position: absolute;
      bottom: -9px;
      right: 58px;
      @include r.sp {
        border-width: 5px 5.5px 0 5.5px;
        bottom: -4px;
        right: 28px;
      }
    }
  }

  //-----------------------------------------------------
  &__ttl {
    font-size: 3rem;
    line-height: 1.68;
    color: v.$color11;
    font-weight: bold;
    letter-spacing: 5px;
    text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff,
      -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
    margin: 3px 0 0;
    @include r.sp {
      font-size: 1.8rem;
      line-height: 1.6;
      letter-spacing: 4px;
      margin: 8px 0 0 11px;
    }
    @include r.max-w(370px) {
      font-size: 1.4rem;
    }

    span {
      font-size: 6rem;
      font-weight: 900;
      letter-spacing: 2.5px;
      @include r.sp {
        font-size: 3rem;
        letter-spacing: 1.5px;
      }
      @include r.max-w(370px) {
        font-size: 2.5rem;
      }
      &.is-medium {
        font-size: 3.6rem;
        @include r.sp {
          font-size: 1.8rem;
        }
      }
    }
  }

  // .c-mv1 .c-btn1 .
  .c-btn1 {
    margin: 11% 0 0;

    @include r.pad {
      margin-top: 4%;
    }

    @include r.sp {
      margin: 8.2% 0 0 10px;
    }
  }
}

/*
------------------------------------------------------------*/
.c-mv2 {
  padding: 14px 0 30px;
  height: auto;
  // height: 515px;
  position: relative;
  display: flex;
  align-items: center;

  @include r.sp {
    height: auto;
    padding: 0 0 10px;
    // height: 248px;
  }

  // .c-mv2:after .
  &:after {
    height: 82%;
    // height: 318px;
    left: 0;
    bottom: -60px;
    position: absolute;
    content: '';
    width: 100%;
    background-color: v.$color13;

    @include r.sp {
      height: 100%;
      // height: 64.115%;
      bottom: -43px;
    }
  }

  > .l-container {
    @include r.calc(767, padding-left, 50, 767);
    @include r.calc(767, padding-right, 50, 767);
  }

  // .c-mv2__title .
  &__title {
    padding-top: 32px;
    position: relative;
    z-index: 2;
    line-height: 1;

    @include r.sp {
      padding-top: 15px;
      // padding-top: 11vw;
    }

    // .c-mv2__title__en .
    &__en {
      letter-spacing: 4px;
      font-size: 6rem;
      color: v.$color11;
      text-transform: uppercase;
      font-weight: 900;
      text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
        1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;

      @include r.sp {
        font-size: 3rem;
        letter-spacing: 0.67vw;
        text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff,
          1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
      }

      // @include r.calc(767, font-size, 60, 767);

      &.is-lowercase {
        text-transform: unset;
      }
    }

    // .c-mv2__title__jp  .
    &__jp {
      // margin-top: 2px;
      letter-spacing: 3px;
      font-size: 2rem;
      line-height: 1.7;
      font-weight: 900;
      color: v.$color4;
      display: block;

      @include r.sp {
        line-height: 1.4;
        font-weight: 700;
        margin-top: 0;
        // font-size: 1rem;
      }

      // @include r.calc(767, font-size, 20, 767);
      @include r.calc(767, letter-spacing, 4, 767);

      &.is-style1 {
        color: v.$color11;
        margin-top: -3px;

        @include r.sp {
          margin-top: -1px;
          text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff;
        }
      }
    }

    // .c-mv2__title.is-style1 .
    &.is-style1 {
      padding-top: 60px;

      @include r.sp {
        padding-top: 55px;
      }
    }
  }

  // .c-mv2__img .
  &__img {
    top: 0;
    position: absolute;
    height: 100%;
    overflow: hidden;
    right: 0;
    width: calc(50% + 560px);
    z-index: 1;

    @include r.max-w(1520px) {
      width: calc(100% - 192px);
    }

    @include r.max-w(768px) {
      width: calc(100% - 140px);
    }

    @include r.sp {
      width: 80.5vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .c-mv2 + .c-breadcrumb .
  & + .c-breadcrumb {
    margin-top: 14px;
    padding-bottom: 19px;
    position: relative;
    z-index: 2;
    background-color: v.$color13;

    @include r.sp {
      margin-top: 7px;
      // margin-top: 12px;
      padding-bottom: 10px;
    }
  }
}
