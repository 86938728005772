@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
text
------------------------------------------------------------*/
.c-text1 {
  font-size: 1.6rem;
  line-height: 1.7;
  color: v.$text-color;
  @include r.sp {
    font-size: 1.4rem;
  }

  &--center {
    text-align: center;
  }
}

/*
------------------------------------------------------------*/
.c-text2 {
  font-size: 2.4rem;
  line-height: 1.7;
  font-weight: 700;
  color: v.$color11;
  text-align: center;
  letter-spacing: 1px;

  @include r.sp {
    font-size: 1.4rem;
    line-height: 2.3rem;
    letter-spacing: 0.5px;
  }
}
