@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.c-header {
  height: 85px;
  background-color: #fff;
  transition: box-shadow 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  @include r.sp {
    height: 60px;
  }

  &.is-scrolling {
    box-shadow: 0 3px 6px rgba($color: #000, $alpha: 0.3);
  }

  &.is-menu-open {
    &:before {
      @include r.sp {
        content: '';
        height: 100vh;
        width: 100%;
        transition: 0.3s ease-in-out;
        background-color: v.$color2;
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
      }
    }
  }

  > .l-container {
    position: relative;
    height: 100%;

    @include r.max-w(888px) {
      padding-left: 15px;
    }

    @include r.sp {
      padding: 0;
    }
  }

  // .c-header__content .
  &__content {
    @include r.pc {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }

    @include r.sp {
      top: 60px;
      position: fixed;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: v.$color2;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }

    &.is-hide {
      @include r.sp {
        display: none;
      }
    }

    &.is-active {
      @include r.sp {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  // .c-header__logo .
  &__logo {
    width: 100%;
    max-width: 151px;
    display: flex;
    align-items: flex-start;

    @include r.pc {
      padding-top: 3px;
    }

    @include r.max-w(1160px) {
      max-width: 124px;
    }

    @include r.pad {
      max-width: 80px;
    }

    @include r.sp {
      padding-top: 2px;
      max-width: 108px;
      margin-right: auto;
    }
  }

  // .c-header__nav .
  &__nav {
    @include r.pc {
      padding-bottom: 3px;
      position: relative;
      z-index: 1;
    }

    @include r.sp {
      padding: 0 20px 100px;
    }
  }

  // .c-header__contact .
  &__contact {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 118px;
    display: flex;
    background-color: v.$color1;
    transition: none;

    @include r.pc {
      max-width: 125px;
      right: 30px;
      z-index: 2;
      box-shadow: v.$shadow;
      position: absolute;
      top: 0;
    }

    @include r.max-w(1250px) {
      right: 0;
    }

    @include r.max-w(1120px) {
      max-width: calc((100 / 1120) * 100vw);
      height: calc((100 / 1120) * 100vw);
    }

    @include r.pad {
      max-width: 100px;
      height: 100px;
    }

    @include r.max-w(999px) {
      max-width: 85px;
      height: 85px;
    }

    @include r.sp {
      width: 60px;
      height: 60px;
    }

    // .c-header__contact:after .
    &:after {
      margin-top: 13px;
      width: 30px;
      height: 20px;
      content: '';
      top: 5px;
      position: relative;
      background: url(p.$path + 'images/common/icon-mail.png') no-repeat center /
        contain;

      @include r.pad {
        top: 0;
      }

      @include r.sp {
        margin-top: 11px;
        top: -4px;
        width: 20px;
        height: 14px;
      }
    }

    // .c-header__contact__text .
    &__text {
      color: #fff;
      font-size: 1.4rem;
      line-height: 1;
      font-weight: 700;
      text-shadow: 1px 0 2px rgba($color: #000, $alpha: 0.28);

      @include r.pc {
        letter-spacing: 0.8px;
      }

      @include r.max-w(1120px) {
        font-size: 1.2rem;
      }

      @include r.pad {
        font-size: 1.2rem;
      }

      @include r.max-w(768px) {
        font-size: 1.2rem;
      }

      @include r.sp {
        font-size: 7px;
      }
    }

    @include r.hover {
      opacity: 0.8;
      transition: 0.3s ease-in-out;
    }
  }

  &__menu {
    @include r.pc {
      display: flex;
    }

    // .c-header__menu__item .
    &__item {
      @include r.pc {
        padding-right: 40.5px;
      }

      @include r.max-w(1250px) {
        padding-right: 30px;
      }

      @include r.max-w(1160px) {
        padding-right: 15px;
      }

      @include r.max-w(1120px) {
        // padding-right: calc((30 / 1120) * 100vw);
      }

      @include r.pad {
        padding-right: 20px;
      }

      @include r.max-w(850px) {
        padding-right: 13px;
      }

      @include r.max-w(799px) {
        padding-right: 10px;
      }

      @include r.sp {
        width: 100%;
      }

      &:last-child {
        padding-right: 0;
      }

      &.is-active {
        .c-header__menu__link {
          &:after {
            width: 100%;
          }
        }
      }
    }

    &__link {
      font-size: 1.4rem;
      color: #333;
      position: relative;
      transition: none;

      @include r.max-w(999px) {
        font-size: 1.2rem;
      }

      @include r.sp {
        border-bottom: 1px solid #fff;
        padding: 10px 0;
        display: block;
        color: #fff;
      }

      &:after {
        height: 2px;
        background-color: v.$color1;
        position: absolute;
        width: 0;
        bottom: -2px;
        left: 0;
        transition: 0.3s ease-in-out;

        @include r.pc {
          content: '';
        }
      }

      @include r.hover {
        opacity: 1;

        &:after {
          width: 100%;
        }
      }
    }
  }

  // .c-header__inner .
  &__inner {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 2px;

    @include r.pad {
      padding-left: 0;
    }

    @include r.sp {
      padding-left: 25px;
    }
  }

  // .c-header__btn .
  &__btn {
    width: 60px;
    height: 60px;
    background-color: v.$color2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include r.pc {
      display: none;
    }

    @include r.sp {
      position: relative;
      z-index: 2;
    }

    // .c-header__btn__line .
    &__line {
      width: 100%;
      height: 1px;
      background-color: #fff;
    }

    // .c-header__btn__lines .
    &__lines {
      top: -5px;
      margin-top: 8px;
      width: 25px;
      height: 18px;
      position: relative;
      display: flex;
      align-items: center;

      &:before,
      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #fff;
        transition: 0.3s ease-in-out;
      }

      &:before {
        top: 0;
        left: 0;
      }

      &:after {
        bottom: 0;
        left: 0;
      }
    }

    &__text {
      @include r.sp {
        font-size: 8px;
        font-weight: 500;
        color: #fff;
      }
    }

    &.is-active {
      .c-header__btn {
        &__line {
          opacity: 0;
        }

        &__lines {
          // .c-header__btn.is-active .c-header__btn__lines:before .
          &:before {
            top: 9px;
            left: 0;
            transform: rotate(45deg);
          }

          // .c-header__btn.is-active .c-header__btn__lines:after .
          &:after {
            bottom: 8px;
            left: 0;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
