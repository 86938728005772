@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

/*------------------------------------------------------------
title
------------------------------------------------------------*/
.c-title1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 40px;

  @include r.sp {
    padding: 0 20px;
  }

  &:not(.no-line) {
    // .c-title1:not(.no-line):after .
    &:after {
      position: absolute;
      top: 58%;
      content: "";
      width: 100%;
      height: 1px;
      border-top: 1px dotted #ccc;

      @include r.sp {
        top: 53%;
      }
    }
  }

  &.no-line {
    .c-title1 {
      &__text {
        background-color: transparent;
      }
    }
  }

  img {
    max-width: 16px;
    display: inline-block;
    position: relative;
    z-index: 1;

    @include r.sp {
      max-width: 12px;
    }
  }

  &__text {
    padding: 0 42px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    position: relative;
    z-index: 1;
    word-break: break-all;
    max-width: 100%;

    @include r.sp {
      margin-top: 10px;
      padding: 0 20px;
    }
  }

  // .c-title1__en .
  &__en {
    font-size: 4.8rem;
    letter-spacing: 3.7px;
    line-height: 1;
    font-weight: normal;
    color: v.$color11;
    @include r.josefin;
    text-transform: uppercase;
    text-align: center;
    word-break: break-word;

    @include r.sp {
      font-size: 2.4rem;
      letter-spacing: 2px;
    }
  }

  // .c-title1__jp .
  &__jp {
    margin-top: 2px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.2;
    color: v.$color8;
    text-align: center;

    @include r.sp {
      margin-top: 3px;
      font-size: 1.2rem;
    }
  }

  // .c-title1.is-style1 .
  &.is-style1 {
    padding-bottom: 39px;

    @include r.sp {
      padding-bottom: 12px;
    }

    // .c-title1.is-style1:before .
    &:before {
      position: absolute;
      content: "";
      width: 20px;
      height: 5px;
      background-color: v.$color10;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);

      @include r.sp {
        height: 3px;
      }
    }

    .c-title1 {
      &__text {
        @include r.sp {
          margin-top: 9px;
        }
      }

      // .c-title1.is-style1 .c-title1__jp .
      &__jp {
        letter-spacing: 1.4px;
        font-size: 3rem;
        color: v.$color11;

        @include r.sp {
          margin-top: 0;
          font-size: 2rem;
        }
      }
    }
  }

  // .c-title1.is-style2 .
  &.is-style2 {
    padding-bottom: 35px;

    @include r.sp {
      padding-bottom: 12px;
    }

    // .c-title1.is-style1:before .
    &:before {
      position: absolute;
      content: "";
      width: 20px;
      height: 5px;
      background-color: v.$color10;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);

      @include r.sp {
        height: 3px;
      }
    }

    .c-title1 {
      &__text {
        margin-top: 27px;

        @include r.sp {
          margin-top: 9px;
        }
      }

      // .c-title1.is-style1 .c-title1__jp .
      &__jp {
        letter-spacing: 1.4px;
        font-size: 3.6rem;
        margin-top: 0;
        color: v.$color11;

        @include r.sp {
          font-size: 2rem;
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-title2 {
  text-align: center;
  font-size: 3rem;
  line-height: 1.5;
  color: v.$color10;
  font-weight: bold;
  letter-spacing: 0.5px;
  @include r.sp {
    font-size: 2rem;
    letter-spacing: 0.3px;
  }

  &.is-style1 {
    font-size: 3.6rem;
    letter-spacing: 0;
    line-height: 1.7;

    @include r.sp {
      font-size: 1.8rem;
    }
  }

  &.is-style2 {
    font-size: 3.6rem;
    letter-spacing: 0.2px;
    @include r.sp {
      font-size: 2rem;
      line-height: 1.3;
    }

    span {
      display: block;
      font-size: 3rem;
      color: v.$color11;
      margin: 4px 0 0;
      @include r.sp {
        font-size: 1.6rem;
        margin: 8px 0 0;
      }
    }
  }
}

/*
------------------------------------------------------------*/
.c-title3 {
  font-size: 3rem;
  font-weight: 500;
  color: #16c1ae;
  letter-spacing: 1px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.3px;
  position: relative;
  @include r.sp {
    font-size: 1.8rem;
    font-weight: 500;
  }
  &::before {
    content: "";
    margin-bottom: 22px;
    display: block;
    height: 1px;
    width: 100%;
    background-color: #cccccc;
    @include r.sp {
      margin-bottom: 10px;
    }
  }
  &:after {
    content: "";
    display: block;
    background: #ffffff url(p.$path + "images/common/icon/icon-top.jpg") no-repeat center center;
    width: 40px;
    height: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -10px;
    @include r.sp {
      width: 12px;
      padding: 0 25px;
      height: 16px;
      padding: 0 20px;
      background-size: contain;
    }
  }
}

/*
------------------------------------------------------------*/
.c-title4 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.7;

  @include r.sp {
    font-size: 2rem;
    line-height: 1.5;
  }
}

/*
------------------------------------------------------------*/
.c-title5 {
  $root: &;
  &__wrap {
    text-align: center;
    display: block;
  }
  &__eng {
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #16c1ae;
    border-radius: 15px;
    display: inline-block;
    padding: 0 35px;
    margin: auto;
    line-height: 1.3;
    letter-spacing: 2px;
    @include r.sp {
      font-size: 1.4rem;
      padding: 0 17px;
      line-height: 1.24;
      letter-spacing: 1.2px;
    }

    #{$root}--style1 & {
      color: #16c1ae;
      background-color: #ffffff;
    }
  }
  &__jp {
    text-align: center;
    display: block;
    font-size: 3.6rem;
    font-weight: 500;
    color: #16c1ae;
    margin: 8px 0 0px 0;
    letter-spacing: 2px;
    @include r.sp {
      font-size: 2rem;
      margin-top: 4px;
    }
    #{$root}--style1 & {
      color: #ffffff;
    }
  }

  // .c-title5 .is-small .
  .is-small {
    font-size: 1.6rem;

    @include r.pc {
      position: relative;
      top: -1px;
    }

    @include r.sp {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

/*
------------------------------------------------------------*/
.c-title6 {
  font-size: 3rem;
  line-height: 1.5;
  color: v.$color11;
  font-weight: bold;
  letter-spacing: 1.6px;
  position: relative;
  padding: 11px 0 14px 38px;
  @include r.sp {
    font-size: 2.4rem;
    letter-spacing: 1.2px;
    padding: 5px 0 8px 33px;
  }

  &::after {
    content: "";
    width: 80px;
    height: 90px;
    background: v.$color4;
    position: absolute;
    top: -20px;
    left: 0;
    @include r.sp {
      width: 60px;
      height: 60px;
      top: -11px;
      left: 0;
    }
  }

  &::before {
    content: "";
    width: 40px;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 40px;
    z-index: 2;
    @include r.sp {
      width: 20px;
      height: 1px;
      left: 39px;
    }
  }

  span {
    position: relative;
    z-index: 3;
  }
}
