@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
side
------------------------------------------------------------*/
.c-sidebar {
  padding: 30px 20px 40px;
  background-color: v.$color13;

  @include r.pc {
    min-width: 300px;
    max-width: 300px;
    margin-left: auto;
  }

  @include r.sp {
    width: 100%;
    padding: 32px 47px 41px;
  }

  // .c-sidebar__box .
  &__box {
    padding: 26px 20px 30px;
    background-color: #fff;

    @include r.sp {
      padding: 28px 22px 32px;
    }

    // .c-sidebar__box .
    & + .c-sidebar__box {
      margin-top: 40px;

      @include r.sp {
        margin-top: 30px;
      }
    }

    .c-sidebar__list2 {
      margin-bottom: -7px;
    }

    .c-list4 {
      margin-top: 30px;
    }
  }

  // .c-sidebar__title .
  &__title {
    padding-left: 5px;
    padding-bottom: 6px;
    margin-bottom: 15px;
    border-bottom: 1px solid #999;
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 700;
    color: v.$color24;
    display: flex;
    align-items: flex-start;

    @include r.sp {
      margin-bottom: 17px;
      padding-bottom: 8px;
    }

    img {
      margin: 4px 9px 0 0;

      @include r.sp {
        margin-right: 12px;
      }
    }

    &.is-icon1 {
      img {
        width: 15px;
      }
    }

    &.is-icon2 {
      img {
        margin-top: 2px;
        width: 16px;
      }
    }

    &.is-icon3 {
      img {
        margin-top: 3px;
        width: 16px;
      }
    }
  }

  &__list1 {
    &__item {
      padding-bottom: 5px;
      border-bottom: 1px solid #ccc;
      width: 100%;

      @include r.sp {
        padding-bottom: 6px;
      }

      // .c-sidebar__list1__item:not(:last-child) .
      &:not(:last-child) {
        margin-bottom: 14px;

        @include r.sp {
          margin-bottom: 16.6px;
        }
      }
    }

    &__link {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  &__list2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3px;

    // .c-sidebar__list2__item .
    &__item {
      padding: 0 3px 7px;
      width: max-content;
      min-width: 33.3333%;

      @include r.sp {
        padding-bottom: 8px;
      }
    }

    // .c-sidebar__list2__link .
    &__link {
      padding: 4px 5px;
      line-height: 1.5;
      width: 100%;
      height: 100%;
      display: block;
      font-size: 1.4rem;
      background-color: v.$color13;
      text-align: center;

      @include r.sp {
        padding: 5px;
      }
    }
  }

  // .c-sidebar__search .
  &__search {
    margin-bottom: 30px;
    position: relative;

    &__btn {
      position: absolute;
      z-index: 1;
      width: 18px;
      height: 18px;
      top: 50%;
      right: 10px;
      cursor: pointer;
      transform: translateY(-50%);
      background: url(p.$path + 'images/common/icon-search.png') no-repeat
        center / contain;

      @include r.sp {
        width: 20px;
        height: 20px;
        right: 11px;
      }
    }

    &__input {
      padding: 0 35px 0 20px;
      font-size: 1.6rem;
      line-height: 3.8rem;
      color: #333;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #ccc;
      box-shadow: v.$shadow;

      @include r.sp {
        line-height: 4.2rem;
        border-radius: 2px;
      }

      &:hover,
      &:focus {
        box-shadow: v.$shadow;
      }
    }
  }
}
