@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
btn
------------------------------------------------------------*/
.c-btn1 {
  max-width: 100%;
  width: 300px;
  $root: &;
  @include r.sp {
    width: 225px;
  }

  &__txt {
    display: block;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    background: linear-gradient(to bottom, v.$color4 50%, v.$color14 50%);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    font-size: 1.8rem;
    line-height: 1.2;
    color: #fff;
    font-weight: bold;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.28);
    padding: 19.5px 10px;
    @include r.sp {
      font-size: 1.4rem;
      border-radius: 5px;
      padding: 14.5px 10px;
    }
  }

  &.is-bg1 {
    .c-btn1__txt {
      background: linear-gradient(to bottom, v.$color18 50%, v.$color30 50%);
    }
  }

  &.is-bg2 {
    .c-btn1__txt {
      background: linear-gradient(to bottom, v.$color31 50%, v.$color32 50%);
    }
  }

  &.is-style1 {
    max-width: 500px;
    width: 100%;

    @include r.sp {
      max-width: 251px;
    }

    .c-btn1 {
      // .c-btn1.is-style1 .c-btn1__txt .
      &__txt {
        padding-top: 28px;
        padding-bottom: 28px;
        letter-spacing: 1px;
        background: linear-gradient(to bottom, v.$color22 50%, v.$color7 50%);
        line-height: 1;
        font-size: 2.4rem;

        @include r.sp {
          padding-top: 14px;
          padding-bottom: 14px;
          font-size: 1.2rem;
        }
      }
    }
  }

  &--style2 {
    width: 240px;
    @include r.sp {
      width: 180px;
    }

    #{$root}__txt {
      font-size: 1.6rem;
      color: v.$text-color;
      border-radius: 4px;
      padding: 20.5px 10px;
      background: linear-gradient(to bottom, v.$color5 50%, v.$color25 50%);
      @include r.sp {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 14px 10px 16px;
        border-radius: 2px;
      }
    }
  }

  &.is-center {
    margin-left: auto;
    margin-right: auto;
  }
}

/*
------------------------------------------------------------*/
.c-btn2 {
  padding: 11px 10px;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  display: block;
  width: max-content;
  min-width: 200px;
  color: v.$color11;
  background-color: #fff;
  border: 2px solid v.$color11;
  box-shadow: v.$shadow1;

  @include r.sp {
    padding: 8px 10px;
    font-size: 1.2rem;
    border-width: 1px;
    min-width: 150px;
  }

  &.is-center {
    margin-left: auto;
    margin-right: auto;
  }

  &.is-right {
    margin-left: auto;
  }

  &.is-style1 {
    border-radius: 4px;
    text-shadow: v.$shadow1;

    @include r.sp {
      border-radius: 2px;
    }
  }
}
