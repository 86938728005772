@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.c-footer {
  background-color: #fff;
  position: relative;

  @include r.sp {
    padding-bottom: 70px;
  }

  > .l-container2 {
    @include r.sp {
      padding: 0;
    }
  }

  // .c-footer__logo .
  &__logo {
    text-align: center;

    @include r.pc {
      width: 196px;
      margin-top: 7px;
      padding: 0 3.95% 45px 0;
      border-right: 1px solid #ccc;
    }

    @include r.max-w(768px) {
      width: 185px;
    }

    @include r.sp {
      width: 100%;
      margin-bottom: 21px;
      padding: 0 15px;
    }

    &__inner {
      @include r.pc {
        display: flex;
      }
    }

    img {
      display: inline-block;

      @include r.sp {
        max-width: 243px;
        width: 100%;
      }
    }
  }

  // .c-footer__top .
  &__top {
    padding: 33px 0 38px;
    background-color: v.$color3;

    @include r.sp {
      padding: 24px 0 18px;
    }

    // .c-footer__top__inner .
    &__inner {
      @include r.pc {
        padding-left: 5px;
        display: flex;
        align-items: flex-start;
      }
    }

    // .c-footer__top__content .
    &__content {
      @include r.pc {
        padding-left: 3.2%;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
      }
    }

    > .l-container2 {
      @include r.sp {
        padding: 0;
      }
    }
  }

  // .c-footer__text1 .
  &__text1 {
    margin-bottom: 28px;
    letter-spacing: 0.8px;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 700;
    color: #fff;
    width: 100%;

    @include r.pc {
      text-align: center;
    }

    @include r.sp {
      margin-bottom: 21px;
      letter-spacing: 0.6px;
      font-size: 1.2rem;
    }
  }

  // .c-footer__text2 .
  &__text2 {
    font-size: 1.1rem;
    line-height: 1.5;
    letter-spacing: 0.6px;
    color: #fff;
    text-align: right;
    width: 100%;

    @include r.sp {
      left: 10px;
      position: relative;
      font-size: 1rem;
    }
  }

  // .c-footer__grmenu .
  &__grmenu {
    margin-bottom: 24px;

    @include r.pc {
      padding-left: 7.4%;
      display: flex;
      flex-wrap: wrap;
    }

    @include r.pad {
      padding-left: 0;
    }

    // .c-footer__grmenu .c-footer__menu2 .
    .c-footer__menu2 {
      @include r.pc {
        width: 33.3333%;
        // width: max-content;
        // max-width: 33.3333%;
      }

      &:not(:last-child) {
        @include r.pc {
          // margin-right: 5%;
        }
      }
    }

    &.is-style1 {
      .c-footer__menu2 {
        padding-right: 0;
        width: 100%;

        @include r.pc {
          display: flex;
          flex-wrap: wrap;
        }

        &__item {
          margin-right: auto;
          width: max-content;
          padding-right: 20px;
          // width: 25%;

          @include r.pad {
            // width: 33.33333%;
          }

          @include r.sp {
            padding-right: 0;
            width: 100%;
          }
        }
      }
    }
  }

  &__menu2 {
    @include r.pc {
      padding-right: 10px;
    }

    // .c-footer__menu2:not(:last-child) .
    &:not(:last-child) {
      @include r.sp {
        margin-bottom: 23px;
      }
    }

    &__item {
      @include r.sp {
        line-height: 1;
      }

      // .c-footer__menu2__item:not(:last-child) .
      &:not(:last-child) {
        @include r.sp {
          margin-bottom: 1px;
        }
      }
    }

    // .c-footer__menu2__link .
    &__link {
      letter-spacing: 0.5px;
      font-size: 1.2rem;
      line-height: 1.5;
      color: #fff;

      @include r.sp {
        line-height: 1;
        font-size: 1rem;
      }
    }
  }

  // .c-footer__grmenu1 .
  &__grmenu1 {
    @include r.pc {
      display: flex;
      padding-right: 15%;
      width: 100%;
    }

    @include r.pad {
      padding-right: 0;
    }

    .c-footer__menu1 {
      @include r.pc {
        width: max-content;
      }

      &__item {
        // .c-footer__grmenu1 .c-footer__menu1__item:not(:last-child) .
        &:not(:last-child) {
          @include r.pc {
            margin-bottom: 9px;
          }
        }
      }

      // .c-footer__grmenu1 .c-footer__menu1:not(:last-child) .
      &:not(:last-child) {
        @include r.pc {
          margin-right: 7.8%;
        }
      }
    }
  }

  // .c-footer__menu1 .
  &__menu1 {
    @include r.sp {
      padding-left: 7px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    @include r.pc {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 600px;
    }

    // .c-footer__menu1__item .
    &__item {
      margin-right: 59px;
      margin-bottom: 9px;

      @include r.pad {
        margin-right: calc((59 / 1024) * 100vw);
      }

      @include r.sp {
        margin-bottom: 2px;
        margin-right: 14px;
      }
    }

    // .c-footer__menu1__link .
    &__link {
      font-size: 1.4rem;
      line-height: 1.5;

      @include r.pc {
        width: max-content;
        white-space: nowrap;
      }

      @include r.sp {
        font-size: 1.2rem;
      }
    }
  }

  // .c-footer__list .
  &__list {
    margin-top: 8px;
    padding-top: 14px;
    border-top: 1px solid #ccc;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include r.sp {
      margin-top: 9px;
      padding: 10px 14px 0;
    }

    // .c-footer__list__item .
    &__item {
      letter-spacing: 1.4px;
      font-size: 1.4rem;
      line-height: 1.5;

      // .c-footer__list__item:not(:last-child) .
      &:not(:last-child) {
        margin-right: 26px;

        @include r.sp {
          margin-right: 13px;
        }
      }

      @include r.sp {
        margin-bottom: 8.5px;
        letter-spacing: 1.1px;
        font-size: 1.2rem;
      }
    }
  }

  // .c-footer__bottom .
  &__bottom {
    padding: 35px 0 18px;
    background-color: v.$color4;

    @include r.sp {
      padding: 36px 0 39px;
    }
  }

  // .c-footer__copyright .
  &__copyright {
    padding: 19px 15px 20px 26px;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1;
    letter-spacing: 1.13px;

    @include r.pad {
      padding: 19px 15px;
    }

    @include r.sp {
      font-size: 1.2rem;
    }

    @include r.max-w(374px) {
      font-size: 1rem;
    }
  }
}

.page-solution {
  .c-footer {
    @include r.sp {
      padding-bottom: 0;
    }
  }
}
