// $filePath: '/dirname/assets/';

$shadow: 0 1px 2px
  rgba(
    $color: #000,
    $alpha: 0.28,
  );

$shadow1: 1px 0 2px
  rgba(
    $color: #000,
    $alpha: 0.28,
  );
$shadow2: 0 3px 6px
  rgba(
    $color: #000,
    $alpha: 0.3,
  );

$shadow3: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff,
  -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;

$base-color: #ff0000;
$text-color: #333;

$color1: #f48e09;
$color2: #0cb7a4;
$color3: #d8f0ed;
$color4: #34d9c6;
$color5: #52f7e4;
$color6: #fdc706;
$color7: #fdc105;
$color8: #15766b;
$color9: #fffd36;
$color10: #16c1ae;
$color11: #222115;
$color12: #ffbf00;
$color13: #eff4fa;
$color14: #31cebc;
$color15: #999999;
$color16: #02544b;
$color17: #919396;
$color18: #fdd835;
$color19: #fff5a0;
$color20: #e8f2f9;
$color21: #cccccc;
$color22: #fdcd06;
$color23: #fdc105;
$color24: #13a393;
$color25: #4eead8;
$color26: #ecf5f4;
$color27: #00b7ee;
$color28: #040203;
$color29: #2fafaf;
$color30: #f0cd32;
$color31: #ff9914;
$color32: #f29113;
$color33: #efefef;
