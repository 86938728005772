@use '../settings/_path' as p;
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: 'Source Han Sans';
  src: url(p.$path + '/fonts/SourceHanSansJP-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Han Sans';
  src: url(p.$path + '/fonts/SourceHanSansJP-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Han Sans';
  src: url(p.$path + '/fonts/SourceHanSansJP-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Source Han Sans';
  src: url(p.$path + '/fonts/SourceHanSansJP-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Han Sans';
  src: url(p.$path + '/fonts/SourceHanSansJP-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
