@use "../settings/variable" as v;
@use "../settings/mixin" as r;
@use "../settings/_path" as p;

.l-side {
  width: 300px;
  background: v.$color13;
  float: right;
  padding: 30px 20px;
  @include r.sp {
    width: auto;
    margin: 0 -15px;
    padding: 30px 47px;
  }

  &__box {
    background: #ffffff;
    padding: 30px 20px;
  }
}
